export const upload = (files) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type:'START_UPLOAD', totUpload: files.length});
    const uid = getState().firebase.auth.uid;
    files.forEach(file => {
      const storageRef = getFirebase().storage().ref("Replays/"+uid+"-"+file.name);
      storageRef.put(file)
      .then((resp) => {
        dispatch({type: 'UPLOAD_SUCCESSFUL'});
      })
      .catch((err) => {
        dispatch({type: 'UPLOAD_ERROR', err});
      })
    });
  }
}

export const endUploadSession = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({type: 'END_UPLOAD_SESSION'});
  }
}