export const heroToIcon = (hero) => {
  const mapHeroToIcon = {
    "Abathur": "abathur_icon.png",
    "Alarak": "alarak_icon.png",
    "Alexstrasza": "alexstrasza_icon.png",
    "Ana": "ana_icon.png",
    "Anduin": "anduin_icon.png",
    "Anub'arak": "anubarak_icon.png",
    "Artanis": "artanis_icon.png",
    "Arthas": "arthas_icon.png",
    "Auriel": "auriel_icon.png",
    "Azmodan": "azmodan_icon.png",
    "Blaze": "blaze_icon.png",
    "Brightwing": "brightwing_icon.png",
    "Cassia": "cassia_icon.png",
    "Chen": "chen_icon.png",
    "Cho": "cho_icon.png",
    "Chromie": "chromie_icon.png",
    "D.Va": "dva_icon.png",
    "Deckard": "deckard_icon.png",
    "Dehaka": "dehaka_icon.png",
    "Diablo": "diablo_icon.png",
    "E.T.C.": "etc_icon.png",
    "Falstad": "falstad_icon.png",
    "Fenix": "fenix_icon.png",
    "Gall": "gall_icon.png",
    "Garrosh": "garrosh_icon.png",
    "Gazlowe": "gazlowe_icon.png",
    "Genji": "genji_icon.png",
    "Greymane": "greymane_icon.png",
    "Gul'dan": "guldan_icon.png",
    "Hanzo": "hanzo_icon.png",
    "Illidan": "illidan_icon.png",
    "Imperius": "imperius_icon.png",
    "Jaina": "jaina_icon.png",
    "Johanna": "johanna_icon.png",
    "Junkrat": "junkrat_icon.png",
    "Kael'thas": "kaelthas_icon.png",
    "Kel'Thuzad": "kelthuzad_icon.png",
    "Kerrigan": "kerrigan_icon.png",
    "Kharazim": "kharazim_icon.png",
    "Leoric": "leoric_icon.png",
    "Li Li": "li-li_icon.png",
    "Li-Ming": "li-ming_icon.png",
    "Lt. Morales": "lt-morales_icon.png",
    "Lúcio": "lucio_icon.png",
    "Lunara": "lunara_icon.png",
    "Maiev": "maiev_icon.png",
    "Malfurion": "malfurion_icon.png",
    "Mal'Ganis": "malganis_icon.png",
    "Malthael": "malthael_icon.png",
    "Medivh": "medivh_icon.png",
    "Mephisto": "mephisto_icon.png",
    "Muradin": "muradin_icon.png",
    "Murky": "murky_icon.png",
    "Nazeebo": "nazeebo_icon.png",
    "Nova": "nova_icon.png",
    "Orphea": "orphea_icon.png",
    "Probius": "probius_icon.png",
    "Ragnaros": "ragnaros_icon.png",
    "Raynor": "raynor_icon.png",
    "Rehgar": "rehgar_icon.png",
    "Rexxar": "rexxar_icon.png",
    "Samuro": "samuro_icon.png",
    "Sgt. Hammer": "sgt-hammer_icon.png",
    "Sonya": "sonya_icon.png",
    "Stitches": "stitches_icon.png",
    "Stukov": "stukov_icon.png",
    "Sylvanas": "sylvanas_icon.png",
    "Tassadar": "tassadar_icon.png",
    "The Butcher": "the-butcher_icon.png",
    "The Lost Vikings": "the-lost-vikings_icon.png",
    "Thrall": "thrall_icon.png",
    "Tracer": "tracer_icon.png",
    "Tychus": "tychus_icon.png",
    "Tyrael": "tyrael_icon.png",
    "Tyrande": "tyrande_icon.png",
    "Uther": "uther_icon.png",
    "Valeera": "valeera_icon.png",
    "Valla": "valla_icon.png",
    "Varian": "varian_icon.png",
    "Whitemane": "whitemane_icon.png",
    "Xul": "xul_icon.png",
    "Yrel": "yrel_icon.png",
    "Zagara": "zagara_icon.png",
    "Zarya": "zarya_icon.png",
    "Zeratul": "zeratul_icon.png",
    "Zul'jin": "zuljin_icon.png",
  }
  return "/resources/icons/heroes_icons/"+mapHeroToIcon[hero];
}

export const displayToStat = (displayKey) => {
  const displayKeyToStat = {
    "kills": "kills", 
    "assists": "assists", 
    "deaths": "deaths", 
    "hero damage": "damage",
    "heal": "heal",
    "xp contribution": "xp",
    "damage taken": "damageTaken",
    "camps cleared": "camps",
    "crowd controls inflicted (sec)": "cc",
    "protections": "protections",
    "clutch heals": "clutchHeals",
    "teamfight heal": "tfHeal",
    "teamfight damage taken": "tfDamageTaken",
    "teamfight hero damage": "tfDamage",
    "MVP": "MVP",
    "map award": "mapAward",
    "outnumbered deaths": "outnumberedDeaths",
    "siege damage": "siege",
  };
  return displayKeyToStat[displayKey];
}

export const statToDisplay = (stat) => {
  const statToDetails = {
    "kills": "kills", 
    "assists": "assists", 
    "deaths": "deaths", 
    "damage": "hero damage",
    "heal": "heal",
    "xp": "xp contribution",
    "damageTaken": "damage taken",
    "camps": "camps cleared",
    "cc": "crowd controls inflicted (sec)",
    "protections": "protections",
    "clutchHeals": "clutch heals",
    "tfHeal": "teamfight heal",
    "tfDamageTaken": "teamfight damage taken",
    "tfDamage": "teamfight hero damage",
    "MVP": "MVP",
    "mapAward": "map award",
    "outnumberedDeaths": "outnumbered deaths",
    "siege": "siege damage",
  }
  return statToDetails[stat];
}

export const isTopEndStat = (statKey) => {
  const isTopEnd = {
    "kills": true, 
    "assists": true, 
    "deaths": false, 
    "damage": true,
    "heal": true,
    "xp": true,
    "damageTaken": true,
    "camps": true,
    "cc": true,
    "protections": true,
    "clutchHeals": true,
    "tfHeal": true,
    "tfDamageTaken": true,
    "tfDamage": true,
    "MVP": true,
    "mapAward": true,
    "outnumberedDeaths": false,
    "siege": true,
  }
  return isTopEnd[statKey];
}

export const statOrder = (statKey, isDisplay) => {
  const statToOrder = {
    "kills": 0, 
    "assists": 1, 
    "deaths": 2, 
    "damage": 0,
    "heal": 0,
    "xp": 0,
    "damageTaken": 0,
    "camps": 1,
    "cc": 0,
    "protections": 1,
    "clutchHeals": 2,
    "tfHeal": 1,
    "tfDamageTaken": 1,
    "tfDamage": 1,
    "MVP": 3,
    "mapAward": 4,
    "outnumberedDeaths": 2,
    "siege": 2,
  };
  const  key = isDisplay ? displayToStat(statKey) : statKey;
  return statToOrder[key];
}

export const statToCat = (stat) => {
  const statToCat = {
    "kills": "takedowns", 
    "assists": "takedowns", 
    "deaths": "takedowns", 
    "damage": "damage",
    "heal": "heal",
    "xp": "tactic",
    "damageTaken": "tank",
    "camps": "tactic",
    "cc": "utility",
    "protections": "utility",
    "clutchHeals": "heal",
    "tfHeal": "heal",
    "tfDamageTaken": "tank",
    "tfDamage": "damage",
    "MVP": "tactic",
    "mapAward": "tactic",
    "outnumberedDeaths": "tactic",
    "siege": "damage",
  }
  return statToCat[stat];
}

export const bigStats = ["xp contribution", "damage taken", "siege damage", "teamfight hero damage",
                         "teamfight damage taken", "heal", "hero damage", "teamfight heal", "protections"];


export const mapNameToData = (mapName) => {
  const res = {
    "Alterac Pass": 
      {image:"alterac-pass", 
       width:256, height:216,
       rx:-20, ry:-20, ox:10, oy:10},
    "Battlefield of Eternity": 
      {image:"battlefield-of-eternity", 
       width:248, height:208,
       rx:5, ry:2, ox:0, oy:0},
    "Blackheart's Bay": 
      {image:"blackhearts-bay", 
       width:256, height:232,
       rx:5, ry:3, ox:0, oy:0},
    "Braxis Holdout": 
      {image:"braxis-holdout", 
       width:256, height:216,
       rx:15, ry:15, ox:5, oy:0},
    "Cursed Hollow": 
      {image:"cursed-hollow", 
       width:256, height:216,
       rx:10, ry:10, ox:0, oy:0},
    "Dragon Shire":
      {image:"dragon-shire", 
       width:248, height:208,
       rx:10, ry:10, ox:0, oy:5},
    "Garden of Terror":
      {image:"garden-of-terror", 
       width:256, height:216,
       rx:4, ry:4, ox:0, oy:0},
    "Hanamura Temple":
      {image:"hanamura", 
       width:256, height:256,
       rx:5, ry:2, ox:0, oy:0},
    "Infernal Shrines":
      {image:"infernal-shrines", 
       width:248, height:208,
       rx:10, ry:4, ox:0, oy:5},
    "Sky Temple":
      {image:"sky-temple", 
       width:248, height:216,
       rx:10, ry:6, ox:0, oy:0},
    "Tomb of the Spider Queen":
      {image:"tomb-of-the-spider-queen", 
       width:248, height:216,
       rx:5, ry:5, ox:0, oy:10},
    "Towers of Doom":
      {image:"towers-of-doom", 
       width:256, height:216,
       rx:4, ry:4, ox:0, oy:15},
    "Volskaya Foundry":
      {image:"volskaya", 
       width:248, height:208,
       rx:7, ry:5, ox:0, oy:15},
    "Warhead Junction":
      {image:"warhead-junction", 
       width:256, height:248,
       rx:10, ry:10, ox:0, oy:8},
  }

  Object.keys(res).forEach(map => {
    res[map].image = "url(/resources/maps/" + res[map].image + ".jpeg)"
  })

  return res[mapName];
}