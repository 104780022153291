import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css'
import { requestReview } from '../../store/actions/authActions'
import "./review.css"

class ModalReview extends Component {

  email = ""

  handleYes = () => {
    this.props.requestReview(this.email, this.props.uid);
    const modalTeam1 = document.querySelector('#modal-review');
    M.Modal.getInstance(modalTeam1).close();
  }

  handleNo = () => {
    const modalTeam1 = document.querySelector('#modal-review');
    M.Modal.getInstance(modalTeam1).close();
  }

  handleChange = (e) => {
    this.email = e.target.value;
  }

  render() {
    this.email = this.props.email;
    return (
      <div className="review-background">
        <h3 className="review-text-center">Participate in our study!</h3>
        <p className="review-text">
          Help us build a better application by answering a few questions! If you click on the yes button,
          you agree to be contacted by email to participate in our evaluation. It only takes about 15 minutes to fill the
          questionnaire and it is entirely up to you to answer it!
        </p>
        <br/>
        <span className="review-text pad-bot">
          Below is the email address on which you want to be contacted:
        </span>
        <div className="input-field review-input">
          <input className="pad-top" type="email" defaultValue={this.props.email} id="email" onChange={this.handleChange} required/>
          <label className="active pad-top" htmlFor="email">Email address</label>
        </div>
        <div className="right">
          <button type="button" className=" btn review-button" name="action" onClick={this.handleNo}>
                <span className="review-text-button"> No </span>
          </button>
          <button type="button" className="review-button btn" name="action" onClick={this.handleYes}>
                <span className="review-text-button"> Yes </span>
          </button>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.firebase.auth.email,
    uid: state.firebase.auth.uid
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestReview: (email, uid) => dispatch(requestReview(email, uid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalReview);