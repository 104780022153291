import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { capitalize } from '../../functions/hotsUtils'
import { statOrder } from '../../functions/translators'

class BarPerf extends Component {

  datasets = ['best', 'player', 'avg']

  colors = {
    tactic: {
      best: "#0b3982",
      player: "#0f52ba",
      avg: "#5786cf",
    },
    heal: {
      best: "#2e6930",
      player: "#4caf50",
      avg: "#a6d7a8",
    },
    damage: {
      best: "#9c1c00",
      player: "#df2800",
      avg: "#ec7e66",
    },
    takedowns: {
      best: "#42257c",
      player: "#5e35b1",
      avg: "#9e86d0",
    },
    utility: {
      best: "#b3903c",
      player: "#FFCD56",
      avg: "#ffe19a",
    },
    tank: {
      best: "#49332b",
      player: "#795548",
      avg: "#af9991",
    }
  }

  handleLabel = (tooltipItem, data) => {
    const value = this.props.data[this.props.cat][tooltipItem.index][this.datasets[tooltipItem.datasetIndex]];
    let label = "";
    if (value > 100000) {
      label = value.toString().slice(0, 3) + "k"
    } else if (value > 10000) {
      label = value.toString().slice(0, 2) + "k"
    } else if (value > 1000) {
      label = value.toString().slice(0, 1) + "." + value.toString().slice(1, 2) + "k"
    } else {
      label = Math.round(value * 100) / 100
      label = label.toString()
    }
    return label;
  }

  handleTitle = (tooltipItem, data) => {
    return this.datasets[tooltipItem[0].datasetIndex].toUpperCase() + " " + tooltipItem[0].label;
  }

  normalize(name, score, best) {
    let newScore = score
    if (name === "clutch heals" && best > 10) {
      newScore *= 10000
    } else if (name === "outnumbered deaths") {
      newScore *= 2000
    } else if (name === "camps cleared" & best > 100) {
      newScore *= 1000
    } else if (name === "crowd controls inflicted (sec)" && best > 200) {
      newScore *= 500
    } else if (name === "MVP" || name === "map award") {
      newScore *= best
    }
    if (newScore === 0) {
      if (best !== 0) {
        newScore = best/50
      } else {
        newScore = 0.1
      }
    }
    return newScore
  }

  makeGraphData() {
    let data = this.props.data[this.props.cat];
    data = data.sort((stat1, stat2) => {
      const order1 = statOrder(stat1.name, true);
      const order2 = statOrder(stat2.name, true);
      return order1 > order2 ? 1 : -1
    });
    const best = Math.max(...(data.map(x => x.best)));
    const labels = data.map(x => capitalize(x.name));
    const bestData = data.map(x => this.normalize(x.name, x.best, best));
    const avgData = data.map(x => this.normalize(x.name, x.avg, best));
    const playerData = data.map(x => this.normalize(x.name, x.player, best));
    return {
      labels,
      datasets: [{
        label:'Best', backgroundColor: this.colors[this.props.cat].best,
        data: bestData
      }, {
        label:'Player', backgroundColor: this.colors[this.props.cat].player,
        data: playerData
      }, {
        label:'Average', backgroundColor: this.colors[this.props.cat].avg,
        data: avgData
      }]
    }
  }

  render() {
    const graphData = this.makeGraphData();
    return (
      <div>
        <Bar data={graphData}
            options= {{
              responsive: true,
              legend: {
                position: 'top',
                labels: {
                  fontFamily: 'MetronicForBlizzard',
                  fontColor: "#FFFFFF",
                  fontSize: 15,
                }
              },
              tooltips: {
                callbacks: {
                  title: this.handleTitle,
                  label: this.handleLabel,
                }
              },
              title: {
                display: true,
                text: capitalize(this.props.cat) + " details",
                fontFamily: 'MetronicForBlizzard',
                fontColor:"#FFFFFF",
                fontSize:18
              },
              scales: {xAxes: [{display: true, ticks:{maxRotation: 0,minRotation: 0, fontFamily:'MetronicForBlizzard', fontColor:"#FFFFFF", fontSize:11}}], 
                       yAxes: [{display: false, ticks:{beginAtZero: true}}]},
              plugins: {
                datalabels: {
                   display: false,
                }
             }
            }}
            redraw
            height={this.props.height}
            
        />
      </div>
    );
  }
}

export default BarPerf;