import { actionTypes } from "redux-firestore";

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().signInWithEmailAndPassword(
      credentials.email, 
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err});
    })
  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().signOut().then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS'})
      dispatch({ type: actionTypes.CLEAR_DATA, preserve: {data: ["defaultTeams"]}});
    })
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    if (newUser.password1 === newUser.password2) {
      firebase.auth().createUserWithEmailAndPassword(
        newUser.email1,
        newUser.password1,
      ).then((resp) => {
        dispatch({ type: 'SIGNUP_SUCCESS'})
      }).catch((err) => {
        dispatch({ type: 'SIGNUP_ERROR', err})
      })
    }

    else {
      dispatch({ type: 'SIGNUP_ERROR', err: { message: 'Passwords do not match!'}})
    }

    
  }
}

export const requestReview = (email, uid) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    getFirestore().collection('reviews').doc(uid).set({[email]:true},{merge:true}).then((resp) => {
      dispatch({type: 'REQUEST_REVIEW_SUCCESS'})
    }).catch((err) => {
      dispatch({type: 'REQUEST_REVIEW_ERROR'})
    })
  }
}