import React, { Component } from 'react';
import { getWinRatio } from '../../functions/gameAnalysis'

class TeamHeader extends Component {


  render() {
    const winClass = this.props.isWinner ? "-win": "";
    return (
      <div className = "row no-space">
        <span className={" col s12 hots-text basic-stats"+winClass+" txt-medium"}>Winrate</span>
        <span className={" col s12 hots-text basic-stats"+winClass+" txt-medium"}>{getWinRatio(this.props.wins, this.props.games)+"%"}</span>
        <span className={" col s6 l5 hots-text basic-stats"+winClass+" txt-medium no-space-at-all"}>{this.props.wins+" wins"}</span>
        <span className={"col s2"}></span>
        <span className={" col s6 l5 hots-text basic-stats"+winClass+" txt-medium no-space-at-all"}>{(this.props.games - this.props.wins)+" losses"}</span>
      </div>
    );
  }
}

export default TeamHeader;