const initState = {
  startTime: 0,
  endTime: 0,
}
const deathMapReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_RANGE":
      console.log("ACTION", action);
      return {
        ...state,
        startTime: action.startTime,
        endTime: action.endTime,
      }
    default:
      return {
        ...state,
      }
  }
}

export default deathMapReducer;