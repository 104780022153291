import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { extractBestPerfs, categorizePerfs, categorizeRelevantStats } from '../../functions/gameAnalysis'
import { bigStats } from '../../functions/translators'
import BarPerf from './BarPerf'
import { capitalize, lowerize } from '../../functions/hotsUtils'


const borderCol = "#252E3B";

const color = {
  takedowns: "#5e35b1",
  damage: "#df2800",
  heal: "#4caf50",
  tactic: "#0f52ba",
  utility: "#FFCD56",
  tank: "#795548",
};

const bgColor = {
  takedowns: "#4527a0",
  damage: "#c92400",
  heal: "#388e3c",
  tactic: "#0e4aa7",
  utility: "#f9a825",
  tank: "#5d4037 ",
};


class Perf extends Component {

  chartReference = {};
  catArray = [];
  currentCat = null;
  barStats = null;
  heightBar = 100;
  state = {
    isActive: false,
    deviceScreen: "large",
  }


  componentDidUpdate = () => {
    this.currentCat = null;
  } 

  trigger = () => {
    const width = window.innerWidth;
    let newDeviceScreen = "large";
    if (width < 310) {
      newDeviceScreen = "extra-small";
    } else if (width < 360) {
      newDeviceScreen = "quite-small";
    } else if (width < 540) {
      newDeviceScreen = "small";
    } else if (width < 993) {
      newDeviceScreen = "medium";
    }
    this.setState({
      isActive: !this.state.isActive,
      deviceScreen: newDeviceScreen,
    })
  }

  handleText = (chart, options) => {
    var ctx = chart.chart.ctx;

    var centerConfig = chart.config.options.elements.center;
    var fontStyle = centerConfig.fontStyle || 'Arial';
    var txt = centerConfig.text;
    var color = centerConfig.color || '#000';
    var sidePadding = centerConfig.sidePadding || 20;
    var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
    ctx.font = "40px " + fontStyle;

    var stringWidth = ctx.measureText(txt).width;
    var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

    var widthRatio = elementWidth / stringWidth;
    var newFontSize = Math.floor(30 * widthRatio);
    var elementHeight = (chart.innerRadius * 2);

    var fontSizeToUse = Math.min(newFontSize, elementHeight);

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
    ctx.font = fontSizeToUse+"px " + fontStyle;
    ctx.fillStyle = color;

    ctx.fillText(txt, centerX, centerY);
  }

  handleFilter = (tooltipItem, data) => {
    if (tooltipItem.index >= this.catArray.length) {
      return false;
    } else {
      return true;
    }
  }

  handleLabel = (tooltipItem, data) => {
    return "";
  }

  handleLabelArray = (tooltipItem, data) => {
    if (tooltipItem.length === 0) return "";
    const label= this.catArray[tooltipItem[0].index].details.map(detail => {
      if (bigStats.includes(detail[0])) {
        return detail[0]+": "+Math.round(detail[1]/1000)+"k"
      } else {
        return detail[0]+": "+Math.round(detail[1]*10)/10
      }
    }).join('\n')
    return label;
  }

  

  handleTitle = (tooltipItem, data) => {
    if (tooltipItem.length === 0) return "";
    this.currentCat = lowerize(data.labels[tooltipItem[0].index]);
    this.setState({});
    return capitalize(data.labels[tooltipItem[0].index]) + " breakdown"
  }

  render() {
    if (!this.state.isActive) return <div></div>;
    const bestPerfs = extractBestPerfs(this.props.perfs.perfStats);
    let categories = null;
    if (this.props.singlePerf){
      categories = categorizePerfs(bestPerfs, this.props.perfs.stats);
    } else {
      categories = categorizePerfs(bestPerfs, this.props.perfs.perfStatsBrut);
    }
    this.catArray = Object.keys(categories).map(cat => categories[cat]).sort((x, y) => x.perf < y.perf ? 1 : -1);
    const dataColor = this.catArray.map(cat => color[cat.catName]);
    const hoverColor = this.catArray.map(cat => bgColor[cat.catName]);
    const datalabels = this.catArray.map(cat => capitalize(cat.catName));
    const borderColArray = this.catArray.map(cat => borderCol)
    let height = 0;
    switch (this.state.deviceScreen) {
      case "large":
        if (this.props.summary){
          height = 250;
          this.heightBar = 250;
        } 
        else height = 100;
        break;
      case "medium":
        height = 100;
        this.heightBar = 200;
        break;
      case "small":
        height = 200;
        this.heightBar = 300;
        break;
      case "quite-small":
        height = 250;
        this.heightBar = 350;
        break;
      case "extra-small":
        height = 350;
        this.heightBar = 450;
        break;
      default:
        height = 100;
        this.heightBar = 200;
        break;
    }
    let dataValues = this.catArray.map(cat => cat.perf);
    if (this.props.perfs.perf !== 100) {
      dataColor.push(borderCol);
      hoverColor.push(borderCol);
      const dataSum = dataValues.reduce((pv, cv) => pv + cv, 0);
      dataValues = dataValues.map(val => val*this.props.perfs.perf/100);
      const remainder = Math.round(dataSum - dataSum*(this.props.perfs.perf/100));
      dataValues.push(remainder);
      borderColArray.push("#FFFFFF00");
    }

    
    if (!this.currentCat) {
      this.currentCat = this.catArray[0].catName;
    }
    this.barStats = this.props.summary ? 
      categorizeRelevantStats(this.props.summary, this.props.playerName, this.catArray)
      :
      null;
    const donutClass = this.props.summary ? "col s12 m12 l6" : "col s12 m12 l12"
    const barperf = this.props.summary ?
        <div className="col s12 m12 l6">
          <BarPerf data={this.barStats}
                   cat={this.currentCat} height={this.heightBar}/>
        </div>
        :
        <div></div>
    const title = this.props.character ? 
      this.props.playerName + " - " + this.props.character
      :
      this.props.playerName;
    return (
      <div className="modal-content">
        <div className="row row-smallestMargin">
          <h4 className="hots-text txt-large">{this.props.titleModal}</h4>
        </div>
        <div className="row row-smallestMargin">
          <h3 className="hots-text">{title}</h3>
        </div>
        <div className="row row-smallestMargin">
          <div className={donutClass}>
            <Doughnut  data={{datasets: [{
                              data: dataValues,
                              backgroundColor: dataColor,
                              borderWidth: 3,
                              borderColor: borderColArray,
                              hoverBorderColor: borderColArray,
                              hoverBorderWidth: 3,
                              hoverBackgroundColor: hoverColor,
                            }],
                            labels: datalabels}}
                          options={{legend: {
                                      display:true,
                                      labels: {
                                        fontFamily: 'MetronicForBlizzard',
                                        fontColor: "#FFFFFF",
                                        fontSize: 15,
                                      }
                                    },
                                    cutoutPercentage: 50,
                                    animation: {
                                      animateRotate: true,
                                    },
                                    tooltips: {
                                      enabled: true,
                                      filter: this.handleFilter,
                                      displayColors: true,
                                      bodyFontFamily: "MetronicForBlizzard",
                                      titleFontFamily: "MetronicForBlizzard",
                                      callbacks: {
                                        label: this.handleLabel,
                                        beforeBody: this.handleLabelArray,
                                        title: this.handleTitle,
                                      },
                                    }, 
                                    circumference: 2*Math.PI,
                                    elements: {
                                      center: {
                                      text: Math.round(this.props.perfs.perf),
                                      color: '#FFFFFF', //Default black
                                      fontStyle: 'MetronicForBlizzard', //Default Arial
                                      sidePadding: 20 //Default 20 (as a percentage)
                                      }
                                    },
                                    maintainAspectRatio: true,
                                    plugins: {
                                      datalabels: {
                                        display: false,
                                      },
                                  }
                                  }}
                          height={height}
                          plugins={[ChartDataLabels,{
                            beforeDraw: this.handleText
                          }]}
                          className="bar" 
                          ref={(reference) => this.chartReference = reference }         
            />
          </div>
          {barperf}
          
        </div>
      </div>
    );
  }
}

export default Perf;