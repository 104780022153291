import React, { Component } from 'react';
import TeamHeader from './TeamHeader';
import BarComp from './BarComp';
import TeamBox from './TeamBox';
import { connect } from 'react-redux';
import { getWinRatio, normalizeTeamStats } from '../../functions/gameAnalysis'
import '../nav/Nav.css'

class Overview extends Component {


  render() {
    let isWinner1 = null;
    let isWinner2 = null;
    if (this.props.team1 && this.props.team2) {
      isWinner1 = getWinRatio(this.props.team1.wins, this.props.team1.n) > getWinRatio(this.props.team2.wins, this.props.team2.n);
      isWinner2 = getWinRatio(this.props.team1.wins, this.props.team1.n) < getWinRatio(this.props.team2.wins, this.props.team2.n);
    }
    if ((this.props.team1 && this.props.team2) && this.props.team1.n === 0) {
      return (
        <div className="row">
          <div className="col s12 m12 l12"><span className="span-fill hots-text txt-medium">{this.props.teamName1+" doesn't match any replay in our database!"}</span></div>
        </div>
      )
    } else if ((this.props.team1 && this.props.team2) && this.props.team2.n === 0) {
      return (
        <div className="row">
          <div className="col s12 m12 l12"><span className="span-fill hots-text txt-medium">{this.props.teamName2+" doesn't match any replay in our database!"}</span></div>
        </div>
      )
    } else if (this.props.team1 && this.props.team2) {
      return (
        <div className="row row-smallestMargin">
          <div className="col s12 m12 l3">
            <TeamHeader wins={this.props.team1.wins} games={this.props.team1.n} isWinner={isWinner1}/>
            <TeamBox side="myTeam" team={this.props.team1} enemy={this.props.team2}/>
          </div>
          <div className="col s12 m12 l6 padding-top-barcomp">
            <div className="col s12 m12 l10 offset-l1">
              <BarComp team1={this.props.team1} team2={this.props.team2}/>
            </div>
          </div>
          <div className="col s12 m12 l3">
            <TeamHeader wins={this.props.team2.wins} games={this.props.team2.n} isWinner={isWinner2}/>
            <TeamBox side="opponentTeam" team={this.props.team2} enemy={this.props.team1}/>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="col s12 m12 l12"><span className="span-fill hots-text txt-medium">For the Overview dashboard, you need to select at least two teams!</span></div>
        </div>
      )
    }
  }
}


const mapStateToProps = (state) => {
  if (state.team.myTeam && state.team.opponentTeam) {
    return {
      team1: normalizeTeamStats(JSON.parse(JSON.stringify(state.team.myTeam))),
      team2: normalizeTeamStats(JSON.parse(JSON.stringify(state.team.opponentTeam))),
      teamName1: state.team.myTeamName,
      teamName2: state.team.opponentTeamName,
    }
  }
  return {}
}

export default connect(mapStateToProps, null)(Overview);