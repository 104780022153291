import React, { Component } from 'react'
import './Nav.css'
import appConfig from "../../config/appConfig"
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { createTeam } from '../../store/actions/teamActions'
import { connect } from 'react-redux'
import M from 'materialize-css'

class ModalTeamCreation extends Component {
  state = {
    teamName: "",
    members: [{key:"teammate1", name:"", currentIdx: 0,}],
    newKey: 1,
    teamNameErrorMsg: "",
    memberNameErrorMsg: "",
  }

  componentDidMount = () => {
    const tooltip = document.querySelector("#team-tooltip");
    const options = {
      position: "bottom",
      html: 'A team can include from 1 up to 7 members.\n <ul><li><span style="font-weight:bold">1-5 members:</span> the analysis considers every game including all the members of the team</li><li><span style="font-weight:bold">6-7 members:</span> the analysis considers every game where five members of the team played</li></ul>'
    };
    M.Tooltip.init(tooltip, options);
  }

  componentDidUpdate = () => {
    const elems = document.querySelectorAll('.autocomplete');
    Array.prototype.forEach.call(elems, elem => {
      const options = {
        limit: appConfig.LIMIT_AUTOCOMPLETE,
        minLength: appConfig.MIN_CHAR_AUTOCOMPLETE,
        data: this.props.players,
        onAutocomplete: ((id) => {
          return ((autoName) => {
            const updatedMembers = this.state.members.slice();
            updatedMembers[id].name = autoName;
            this.setState({
              members: updatedMembers,
          })})
        })(elem.id)
      }
      M.Autocomplete.init(elem, options);
    })
  }


  handleTeamChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleChange = (e) => {
    const updatedMembers = this.state.members.slice();
    updatedMembers[e.target.id].name = e.target.value;
    this.setState({
      members: updatedMembers,
    });
  }

  handleAdd = (e) => {
    const newMembers = this.state.members.slice();
    const newKey = this.state.newKey + 1;
    newMembers.push({key:"teammate"+newKey, name:"", currentIdx: newMembers.length});
    this.setState({
      members: newMembers,
      newKey: newKey
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const sortedMembers = this.state.members.sort();
    let hasSame = false;
    for (let i = 0; i < sortedMembers.length-1; i++) {
      if (sortedMembers[i].name === sortedMembers[i+1].name) {
        hasSame = true;
      }
    }
    if (this.state.teamName.length < 2) {
      this.setState({
        teamNameErrorMsg: <span className="red-text">Your team name must contain at least 2 characters!</span>,
      })
    } else if (this.state.teamName.length > appConfig.MAX_TEAM_NAME_LENGTH) {
      this.setState({
        teamNameErrorMsg: <span className="red-text">{"Your team name must contain at most "+ appConfig.MAX_TEAM_NAME_LENGTH +" characters!"}</span>,
      })
    } else if (this.state.members.map(x => x.name).filter(name => name.trim().length >= 1).length === 0) {
      this.setState({
        memberNameErrorMsg: <span className="red-text">You must enter at least one member name!</span>
      })
    } else if (this.state.members.map(x => x.name).filter(name => name.trim().length >= appConfig.MAX_MEMBERS_NAME_LENGTH).length >= 1) {
      this.setState({
        memberNameErrorMsg: <span className="red-text">{"Members name cannot exceed "+appConfig.MAX_MEMBERS_NAME_LENGTH+" characters!"}</span>
      })
    } else if (hasSame) {
      this.setState({
        memberNameErrorMsg: <span className="red-text">Team mates cannot have the same name!</span>
      })
    }else {
      this.props.createTeam(this.state); 
      this.setState({
        teamName: "",
        members: [{key:"teammate1", name:"", currentIdx: 0,}],
        teamNameErrorMsg: "",
        memberNameErrorMsg: "",
      })
    }
  }

  handleRemove = (id) => {
    const updatedMembers = [];
    for(let i = 0; i<this.state.members.length; i++) {
      if (i !== id) {
        const updatedMember = this.state.members[i];
        updatedMember.currentIdx = i > id ? i-1 : i;
        updatedMembers.push(updatedMember);
      }
    }
    this.setState({
      members: updatedMembers,
    })

  }

  renderTeammates = () => {  
    const htmlTemplate = []
    this.state.members.forEach( elem => {
      htmlTemplate.push(
      <div key={elem.key} className="input-field row"> 
        <label className="active"htmlFor={elem.currentIdx}>team mate {elem.currentIdx+1}</label> 
        <input value={elem.name} type="text" id={elem.currentIdx} className="autocomplete hots-text align-left col s10" onChange={this.handleChange}/>
        <button type="button" className="btn-small hots-button col s1"  onClick={ () => this.handleRemove(elem.currentIdx)}><i className="material-icons">close</i> </button>
      </div>
    )})
    return htmlTemplate;
  }

  handleNothing = (e) => {
    e.preventDefault();

  }

  render() {
    const display = this.state.members.length === 1 ? 
      <div key={this.state.members[0].key} className="input-field row row-smallerMargin"> 
        <input value={this.state.members[0].name} type="text" id={0} className="autocomplete  hots-text align-left col s11" onChange={this.handleChange}/>
        <label className="active hots-text align-left" htmlFor={0}>team mate {1}</label> 
      </div>
        : 
      this.renderTeammates();
    const disabled = this.state.members.length === appConfig.MAX_MEMBERS ? "disabled" : "";
    return (
      <div className="modal-content">
        <div className="row row-smallerMargin">
          <div className="col s3"></div>
          <div className="col s6">
            <span className="hots-text txt-large span-fill">Create A Team</span>
          </div>
          <div className="col s3">
            <a id={"team-tooltip"} className="btn-floating btn-small tooltipped left hots-tooltip no-background tooltip-border" href="# ">
              <i><span className="hots-text no-left-pad">?</span></i>
            </a>
          </div>
        </div>
        <div className="row row-smallestMargin">
          <form onSubmit={this.handleNothing} autoComplete="off" className="hots-text align-left col s12">
            <div className="input-field row no-space">
              <input id="teamName" type="text" className="hots-text align-left" value={this.state.teamName} onChange={this.handleTeamChange}/>
              <label htmlFor="teamName">Team name</label>
              {this.state.teamNameErrorMsg}
            </div>
            <div className="row row-smallerMargin"> 
              <h3 className="hots-text txt-medium">Members</h3>
            </div>
            {this.state.memberNameErrorMsg}
            {display}
            <div className="row">
              <button type="button" id="add-members-button" className={"hots-button waves-effect waves-light btn "+disabled} onClick={this.handleAdd}>
                  <span className="hots-text">add member</span>
              </button>
              <button type="button" className="hots-button right btn waves-effect waves-light" name="action" onClick={this.handleSubmit}>
                <span className="hots-text"> Submit </span>
                <i className="material-icons right">send</i>
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  
}


const extractPlayers = (docs) => {
  const data = {};
  if (docs) {
    docs.forEach(doc => {
      for (let player in doc) {
        if (doc.hasOwnProperty(player)) {
          if (doc[player].picUrl) data[player] = "/resources/usericons/"+doc[player].picUrl;
        }
      }
    })
  }
  return data;
} 

const mapDispatchToProps = (dispatch) => {
  return {
    createTeam: (team) => dispatch(createTeam(team))
  }
}

const mapStateToProps = (state) => {
  return {
    players: extractPlayers(state.firestore.ordered.players),
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'players' }
  ])
)(ModalTeamCreation);