import React, { Component } from 'react';
import { upload, endUploadSession } from '../../store/actions/uploadActions'
import { connect} from 'react-redux'
import appConfig from '../../config/appConfig';

class UploadReplay extends Component {

  state = {
    files: [],
  }


  componentWillMount = () => {
    this.props.endUploadSession();
  }

  handleChange = (e) => {
    this.setState({
      files: e.target.files,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.files.length !== 0){
      const validFiles = Array.prototype.filter.call(this.state.files, file => {
        const ext = file.name.split(".");
        return ext.length >= 1 && ext[ext.length -1].toLowerCase() === "stormreplay"
      });
      if (validFiles.length !== 0){
        this.props.upload(validFiles);
        this.setState({
          files: [],
        })
      }
    }
  }

  displayErrors = () => {
    const errMsg = this.props.errors.length === 0 ? <div></div> : <span className="red-text">There are {this.props.errors.length} uploading errors</span>;
    const errFiles = this.props.errors.map((file) => {
      return(
        <div key={file}>
          <span className="red-text">Error when uploading: {file}</span>
        </div>
      )
    });
    return (
      <div>
        {errMsg}
        {errFiles}
      </div>)
  }

  render() {
    const progressBar = this.props.nbUpload !== -1 ? 
      <div className="progress progress-background">
        <div className="determinate progress-filler" style={{width:(this.props.nbUpload / this.props.totalUpload)*100+"%" }}></div>
      </div>
      :
      <div></div>;
    const isDisabled = this.state.files.length > appConfig.MAX_REPLAYS ? "disabled" : "";
    const errorTooMany = this.state.files.length > appConfig.MAX_REPLAYS ? 
    "At the moment, you cannot upload more than "+appConfig.MAX_REPLAYS+" replays at once"
    :  "";
    return (
      <div className="container">
        <h4 className="hots-text txt-large">Upload Replays</h4>
        {progressBar}
        <form action="#" onSubmit={this.handleSubmit}>
          <div className="file-field input-field">
            <div className="hots-button btn">
              <i className="material-icons right">folder</i>
              <span className="hots-text">Browse</span>
              <input type="file" multiple onChange={this.handleChange}/>
            </div>
            <div className="file-path-wrapper">
              <input className="hots-text align-left" type="text" value={this.state.files.length+" replay(s) waiting to be uploaded"} onChange={this.handleChange}/>
            </div>
          </div>
          <button className={"btn pad hots-button waves-effect waves-light right "+isDisabled} type="submit" name="action" onClick={this.handleSubmit}>
              <span className="hots-text">Submit</span>
              <i className="material-icons right">send</i>
          </button>
        </form>
        <span className="red-text">{errorTooMany}</span>
        {this.displayErrors()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (file) => dispatch(upload(file)),
    endUploadSession: () => dispatch(endUploadSession()),
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.upload.errors,
    nbUpload: state.upload.nbUpload,
    totalUpload: state.upload.totalUpload,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadReplay);