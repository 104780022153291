import React, { Component } from 'react'
import './Dropdown.css'
import {extractReplayName, getSide} from '../../functions/gameAnalysis'
import { connect } from 'react-redux';
import { selectMatch} from '../../store/actions/teamActions'

class DropdownH2H extends Component {

  state = {
    active: "",
    width: 300,
  }

  handleClickButton = () => {
    let newActive = "";
    if (this.state.active === "") {
      newActive = "is-dropdown-active";
    }
    this.setState({
      active: newActive,
    })
  }

  handleResize = ()  => {
    this.setState({
      width: document.getElementById('dropdown-replays').offsetWidth,
    })
    
  }

  componentDidMount() {
    //this.props.setDrop(this.resetName, this.props.teamId);
    this.setState({
      width: document.getElementById('dropdown-replays').offsetWidth,
    })
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
    window.removeEventListener('resize', this.handleResize);
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    if (this.state.active !== "") {
      this.setState({
        active: "",
      });
    }
  }

  handleSelectMatch = (e) => {
    this.props.selectMatch(this.props.matches[e.currentTarget.dataset.id]);
    this.setState({
      active: "",
    });
  }

  renderTeams = () => {
    const res = [];
    this.props.matches.forEach((match, idx) => {
      res.push(
        <li key={match.id} data-id={idx} onClick={this.handleSelectMatch} className="li-drop Nav-dropdownItem truncate">
          {renderDot(match, this.props.team, this.props.isLeft)}
          <span className="hots-text-el Nav-item-text align-left">{extractReplayName(match)}</span>
        </li>
      )  
    });
    return res;
  }

  render() {
    return (
      <div ref={node => this.node = node} className="dropdown drop-button">
        <button id={"dropdown-replays"} className={"btn hots-button wave-effect wave-light Nav-link drop-button truncate "+this.state.active} onClick={this.handleClickButton}>
          <i className="material-icons right">arrow_drop_down</i>
          <span className="hots-text">{this.props.currentName}</span>
          {renderDot(this.props.currentMatch, this.props.team, this.props.isLeft)}
          <span className="hots-text">win</span>
        </button>
        <div className="Nav-dropdown">
          <div className="Nav-dropdownBox" style={{width: this.state.width+"px"}}>
            <div className="Nav-dropdownBoxInner">
              <ul className="ul-drop Nav-dropdownList">
                {this.renderTeams()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const renderDot = (match, members, isLeft) => {
  if (!match) return <div></div>;
  const side = getSide(match, members) -1;
  return isRed(match, side, isLeft) ? <span className="dot-loose pad-left-drop"></span> : <span className="dot-win pad-left-drop"></span>;
}

const isRed = (match, side, isLeft) => {
  const hasWon = match.winner === side;
  if (hasWon && isLeft) return false;
  if (hasWon && !isLeft) return true;
  if (!hasWon && isLeft) return true;
  else return false;
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectMatch: (match) => dispatch(selectMatch(match)),
  }
}

const mapStateToProps = (state) => {
  return {
    currentName: extractReplayName(state.team.currentMatch),
    currentMatch: state.team.currentMatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownH2H);