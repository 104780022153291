import React, { Component } from 'react';
import HeroStats from './HeroStats';

class GameStats extends Component {


  isWinner = (stats, index) => {
    const wins = []
    Object.keys(stats).forEach(stat => {
      if (stat === "deaths") {
        wins.push(Math.min(...stats[stat]) === stats[stat][index]);
      }
      else {
        wins.push(Math.max(...stats[stat]) === stats[stat][index]);
      }
      
    })
    return wins;
  }

  render() {

    const stats = []
    const heroes = []
    const statsOrdered = {
      kills: [], assists: [], deaths: [],
      damage: [], heal: [], siege: []
    }
    
    this.props.players.forEach(player => {
      stats.push(player.stats);
      heroes.push(player.character);
      Object.keys(statsOrdered).forEach(stat => {
        statsOrdered[stat].push(player.stats[stat]);
      })
      
    })

    return (
      <div className="teambox">
        <div className="row row-smallestMargin-bottom">
          <div className="col s2"></div>
          <div className="col s10">
            <div className="col s2">
              <img src="/resources/icons/kill.png" alt="" className="center"/>
            </div>
            <div className="col s2">
              <img src="/resources/icons/assist.png" alt="" className="center"/>
            </div>
            <div className="col s2">
              <img src="/resources/icons/dead.png" alt="" className="center"/>
            </div>
            <div className="col s2">
              <img src="/resources/icons/hero_damage.png" alt="" className="center"/>
            </div>
            <div className="col s2">
              <img src="/resources/icons/healing.png" alt="" className="center"/>
            </div>
            <div className="col s2">
              <img src="/resources/icons/struct_damage.png" alt="" className="center"/>
            </div>
          </div>
        </div>

        <div className="blueish">
        <HeroStats idCol={0} hero={heroes[0]} stats={stats[0]} wins={this.isWinner(statsOrdered, 0)}/>
        <HeroStats idCol={1} hero={heroes[1]} stats={stats[1]} wins={this.isWinner(statsOrdered, 1)}/>
        <HeroStats idCol={2} hero={heroes[2]} stats={stats[2]} wins={this.isWinner(statsOrdered, 2)}/>
        <HeroStats idCol={3} hero={heroes[3]} stats={stats[3]} wins={this.isWinner(statsOrdered, 3)}/>
        <HeroStats idCol={4} hero={heroes[4]} stats={stats[4]} wins={this.isWinner(statsOrdered, 4)}/>
        </div>
        <div className="redish">
        <HeroStats idCol={5} hero={heroes[5]} stats={stats[5]} wins={this.isWinner(statsOrdered, 5)}/>
        <HeroStats idCol={6} hero={heroes[6]} stats={stats[6]} wins={this.isWinner(statsOrdered, 6)}/>
        <HeroStats idCol={7} hero={heroes[7]} stats={stats[7]} wins={this.isWinner(statsOrdered, 7)}/>
        <HeroStats idCol={8} hero={heroes[8]} stats={stats[8]} wins={this.isWinner(statsOrdered, 8)}/>
        <HeroStats idCol={9} hero={heroes[9]} stats={stats[9]} wins={this.isWinner(statsOrdered, 9)}/>
        </div>

      </div>
    );
  }
}

export default GameStats;