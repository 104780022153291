import React, { Component } from 'react';
import './Diamond.css'
class Diamond extends Component {
  render() {
    const col = this.props.col ? this.props.col : "s5th";
    const cursor = this.props.cursor ? this.props.cursor : ""
    const hover = this.props.cursor ? "hover-hero-pic" : ""
    return (
      <div className={"col " + col + " small-pad "+hover}>
        <img src={this.props.hero} alt="" className={"hero-icon-small center"}/>
        <div className={"overlay "+cursor}></div>
      </div>
    );
  }
}

export default Diamond;