export const concatTeams = (teams1, teams2) => {
  let res = null;
  if (teams1 && !teams2) res = teams1;
  else if (teams2 && !teams1) res = teams2;
  else if (!teams1 && !teams2) return null;
  else {
    const teams = {}
    Object.keys(teams1).forEach(team => {
      if (teams1.hasOwnProperty(team)) teams[team] = teams1[team];
    })
    Object.keys(teams2).forEach(team => {
      if (teams2.hasOwnProperty(team)) teams[team] = teams2[team];
    })
    res = teams;
  }
  let newRes = {};
  Object.keys(res).forEach(teamId => {
    if (res[teamId]) {
      newRes[teamId] = res[teamId]
    }
  });
  return newRes;
}

export const div = (val, n) => {
  return n > 0 ? val/n : 0;
}

export const range = (start, end) => {
  const res = [];
  for (let i = start; i < end; i++) {
    res.push(i);
  }
  return res;
}

export const timeToSec = (time) => {
  return time.hours*3600+time.minutes*60+time.seconds;
}

export const secToTime = (secs) => {
  const hours = Math.floor(secs / 3600);
  const remainder = secs % 3600;
  const minutes = Math.floor(remainder/60);
  const newSecs = remainder % 60;
  return {hours, minutes, secs: newSecs};
}

export const secToTimestamp = (sec) => {
  const h = Math.floor(sec/3600);
  let r = sec % 3600;
  const m = Math.floor(r/60);
  r = r % 60;
  const s = Math.floor(r);
  const h_s = "0"+h;
  const m_s = m > 9 ? m.toString() : "0"+m;
  const s_s = s > 9 ? s.toString() : "0"+s;
  return h_s + ":" + m_s + ":" + s_s;
}

export const timeInInterval = (deathTime, startTime, endTime) => {
  const deathTimeSec = timeToSec(deathTime);
  return deathTimeSec >= startTime && deathTimeSec <= endTime
}

export const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const lowerize = (s) => {
  return s.charAt(0).toLowerCase() + s.slice(1)
}

export const dateToString = (date) => {
  const month = date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth();
  const day = date.getDate() > 9 ? date.getDate() : "0"+date.getDate();
  const hours = date.getHours() > 9 ? date.getHours() : "0"+date.getHours();
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : "0"+date.getMinutes();
  const seconds = date.getSeconds() > 9 ? date.getSeconds() : "0"+date.getSeconds();
  return date.getFullYear() + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}


export const formatBigNumber = (nb) => {
  let newNb = nb.toString();
  if (newNb.length === 6) newNb = newNb.slice(0,3) + "," + newNb.slice(3);
  if (newNb.length === 5) newNb = newNb.slice(0,2) + "," + newNb.slice(2);
  if (newNb.length === 4) newNb = newNb.slice(0,1) + "," + newNb.slice(1);
  return newNb;
}