import { mergeReplays } from "../../functions/gameAnalysis";
import { timeToSec } from "../../functions/hotsUtils"

export const createTeam = (team) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to database
    const firestore = getFirestore();
    const state = getState();
    const members = team.members.map(x => x.name).filter(name => name.trim().length >= 1);
    firestore.collection('teams').add({
      user: state.firebase.auth.uid,
      name: team.teamName,
      members: members,
    }).then(() => {
      dispatch({ type:'CREATE_TEAM', team });
    }).catch((err) => {
      dispatch({ type:'CREATE_TEAM_ERROR', team, err });
    }) 
  }
}


const getReplays = (ids, dispatch, firestore) => {
  dispatch({type:"UPDATE_RANGE", startTime: 0, endTime: 0});
  if (ids.length === 0) {
    dispatch({ type:'REPLAYS_NOT_FOUND' });
  }
  else {
    const promises = ids.map(id => firestore.collection('replays').doc(id).get())
    return Promise.all(promises).then( (replaysSnap) => {
      const replays = [];
      replaysSnap.forEach(replay => {
        replays.push(replay.data());
      })
      dispatch({ type:'REPLAYS_FOUND', replays });
    }).catch(error => {
      console.error(error);
      dispatch({ type:'REPLAYS_NOT_FOUND' });
    })
  }
  return;
}


export const tryFetchReplays = () => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const state = getState();
    const replays1 = state.team.myTeam ? state.team.myTeam.replays : undefined;
    const replays2 = state.team.opponentTeam ? state.team.opponentTeam.replays : undefined;
    if (replays1 || replays2)
      getReplays(mergeReplays(replays1, replays2), dispatch, getFirestore());
  }
}


export const getTeamStats = (team, isMine, shouldFetch) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const state = getState();
    const firestore = getFirestore();
    const members = [...team.members].sort();
    const id = members.join("");
    return firestore.collection('teamStats').doc(id).get().then(snapshot => {
      if (snapshot.exists) {
        const teamStats = snapshot.data();
        dispatch({type:'STATS_FOUND', isMine: isMine, teamName: team.name, data: teamStats});
        if (shouldFetch) {
          let otherReplays = undefined;
          if (!isMine && state.team.myTeam) otherReplays = state.team.myTeam.replays;
          else if (isMine && state.team.opponentTeam) otherReplays = state.team.opponentTeam.replays;
          const replaysId = mergeReplays(teamStats.replays, otherReplays);
          return getReplays(replaysId, dispatch, firestore);
        }
      } else {
        dispatch({type:'NO_STATS_FOUND'});
      }
    }).catch(err => {
      dispatch({type:'GET_STATS_ERROR', err});
    })
  }
}

export const deselectTeam = (isMine) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const state = getState();
    dispatch({type:"DESELECT_TEAM", isMine: isMine});
    const team = isMine ? state.team.opponentTeam : state.team.myTeam;
    if (team) {
      const replays = team.replays;
      return getReplays(replays, dispatch, getFirestore());
    }
    else {
      dispatch({type:'EMPTY_REPLAYS'});
    }
  }
}

export const selectMatch = (match) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    dispatch({type:"SELECT_MATCH", match: match});
    dispatch({type:"UPDATE_RANGE", startTime: 0, endTime: timeToSec(match.duration)});
  }
}

export const deleteTeam = (teamId, teamName) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    // Actually delete the team
    const firestore = getFirestore();
    firestore.collection('teams').doc(teamId).delete();
    dispatch({type:"TEAM_DELETED", teamId, teamName});
  }
}