import React, { Component } from 'react'
import { connect } from 'react-redux';
import { concatTeams } from '../../functions/hotsUtils'
import './Dropdown.css'

class Dropdown extends Component {

  state = {
    active: "",
    selectedName: this.props.dropName,
    width: 300,
  }

  handleClickButton = () => {
    let newActive = "";
    if (this.state.active === "") {
      newActive = "is-dropdown-active";
    }
    this.setState({
      active: newActive,
    })
  }

  handleResize = ()  => {
    this.setState({
      width: document.getElementById('drop-button-'+this.props.teamId).offsetWidth,
    })
    
  }

  componentDidMount() {
    this.props.setDrop(this.resetName, this.props.teamId);
    this.setState({
      width: document.getElementById('drop-button-'+this.props.teamId).offsetWidth,
    })
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
    window.removeEventListener('resize', this.handleResize);
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    if (this.state.active !== "") {
      this.setState({
        active: "",
      });
    }
  }

  resetName = () => {
    this.setState({
      selectedName: "Select Team",
      active: "",
    });
  }

  handleDeselect = () => {
    this.props.handleSelectTeam(null, this.props.teamId);
    this.resetName();
  }

  handleSelectTeam = (e) => {
    this.props.handleSelectTeam(this.props.myTeams[e.currentTarget.dataset.id], this.props.teamId);
    this.setState({
      selectedName: this.props.myTeams[e.currentTarget.dataset.id].name,
      active: "",
    });
  }

  renderTeams = () => {
    if (this.props.myTeams) {
      const keys = Object.keys(this.props.myTeams);
      if (keys.length === 0) {
        return (
          <li className="li-drop Nav-dropdownItem truncate">
            <span className="hots-text-el Nav-item-text align-left">You don't have any team create one!</span>
          </li>
        );
      } else {
        const res = [];
        if (this.state.selectedName !== "Select Team"){
          res.push(<li key={"noTeam"+this.props.teamId} data-id={"noTeam"+this.props.teamId} onClick={this.handleDeselect} className="li-drop Nav-dropdownItem truncate">
                    <span className="hots-text-el Nav-item-text align-left">No team</span>
                  </li>)
        }
        keys.forEach((key, idx) => {
          res.push(
            <li key={key} data-id={key} onClick={this.handleSelectTeam} className="li-drop Nav-dropdownItem truncate">
              <span className="hots-text-el Nav-item-text align-left">{this.props.myTeams[key].name}</span>
            </li>
          )  
        });
        return res;
      }
      
    }
    return (
      <li className="li-drop Nav-dropdownItem truncate">
        <span className="hots-text-el Nav-item-text align-left"> You don't have any team create one!</span>
      </li>
    );
  }

  render() {
    return (
      <div ref={node => this.node = node} className="dropdown drop-button">
        <button id={"drop-button-"+this.props.teamId} className={"btn hots-button wave-effect wave-light Nav-link drop-button truncate "+this.state.active} onClick={this.handleClickButton}>
          <i className="material-icons right">arrow_drop_down</i>
          {this.props.teamId === 1 ? <span className="dot-loose"></span> : <span className="dot-win"></span>}
          <span className="hots-text">{this.state.selectedName}</span>
        </button>
        <div className="Nav-dropdown">
          <div className="Nav-dropdownBox" style={{width: this.state.width+"px"}}>
            <div className="Nav-dropdownBoxInner">
              <ul className="ul-drop Nav-dropdownList">
                {this.renderTeams()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    myTeams:  concatTeams(state.firestore.data["myTeams"], state.firestore.data["defaultTeams"]),
  }
}

export default connect(mapStateToProps, null)(Dropdown);