import React, { Component } from 'react';
import {HorizontalBar} from 'react-chartjs-2';
import './Bar.css';
import '../nav/Nav.css'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {scaleValues} from '../../functions/gameAnalysis'

class Bar extends Component {
  render() {
    const myColor = "#0387C5";
    const enemyColor = "#D62752";
    const neutralColor = "#A3AACC77";
    const scaledValues = scaleValues(this.props.values);
    const x = scaledValues[0], y = scaledValues[1];
    const color1 = x >= y ? myColor : neutralColor;
    const color2 = x <= y ? enemyColor : neutralColor;

    const textStyle1 = {color:'white', textShadowColor:'#009cff', textShadowBlur:5};
    const textStyle2 = {color:'#CCCCFF'};
    const fontStyle = {size: '14', family: 'MetronicForBlizzard', weight: '300'};

    const textStyleX = x >= y ? textStyle1 : textStyle2;
    const textStyleY = x <= y ? textStyle1 : textStyle2;

    const title = this.props.title ? 
      <span className="hots-text span-fill bar-title">{this.props.title}</span>
      :
      <div></div>
    return (   
      <div className="bar">
        {title}
        <HorizontalBar data={{datasets: [
                                {data: [x], backgroundColor: color1, label: this.props.title + "1",
                                 datalabels: {align: 'start', anchor: 'start', ...textStyleX, font: fontStyle}}, 
                                {data: [y], backgroundColor: color2, label: this.props.title + "2",
                                 datalabels: {align: 'end', anchor: 'end', ...textStyleY, font: fontStyle}}
                              ],
                              labels: this.props.values.map(x => Math.round(x))}}
                       options={{legend: {display:false},
                                tooltips: {enabled:false},
                                scales: {xAxes: [{display: false, stacked: true}], 
                                         yAxes: [{display: false, stacked: true, ticks: {mirror:true}, barThickness:30}]},
                                maintainAspectRatio: true,
                                layout: {
                                  padding: {
                                      left: 50,
                                      right: 50,
                                      top: 0,
                                      bottom: 0
                                  }
                                },
                                plugins: {
                                  datalabels: {
                                     display: true,
                                     formatter: function(value, context) {
                                       return context.chart.data.labels[context.datasetIndex];
                                     }
                                  }
                               }}}
                       height={34}
                       plugins={[ChartDataLabels]}
                       className="bar"          
        />
      </div>
    );
  }
}

export default Bar;