import React, { Component } from 'react';
import { signUp } from '../../store/actions/authActions';
import { connect } from 'react-redux';

class ModalSignup extends Component {

  state = {
    email1: '',
    password1: '',
    password2: '',
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signUp(this.state);
  }

  render() {
    const { authError } = this.props
    return (
      <div className="modal-content">
        <h4 className="hots-text txt-large">Signup</h4>
        <form id="signup-form" onSubmit={this.handleSubmit}>
          <div className="input-field">
            <input type="email" id="email1" onChange={this.handleChange} required/>
            <label htmlFor="email1">Email address</label>
          </div>
          <div className="input-field">
            <input type="password" id="password1" onChange={this.handleChange} required/>
            <label htmlFor="password1">Choose password</label>
          </div>
          <div className="input-field">
            <input type="password" id="password2" onChange={this.handleChange} required/>
            <label htmlFor="password2">Confirm password</label>
          </div>
          <button className="btn hots-button"><span className="hots-text">Sign up</span></button>
          <span className="hots-text-red">{ authError ? <p>{authError}</p> : null}</span> 
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ModalSignup);