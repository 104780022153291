import React, { Component } from 'react';

class StatLine extends Component {
  render() {
    return (
      <div>
        <div className="row no-space">
            <div className="col s2">
              <img src={this.props.data.img[0]} alt="" className="center"/>
            </div>
            <div className="col s4">
              <span className={"col s12 hots-text align-left basic-stats"+this.props.data.win[0]}>
                {this.props.data.stat[0]}
              </span>
            </div>
            <div className="col s2">
              <img src={this.props.data.img[1]} alt="" className="center"/>
            </div>
            <div className="col s4">
              <span className={"col s12 hots-text align-left basic-stats"+this.props.data.win[1]}>
                {this.props.data.stat[1]}
              </span>
            </div>
          </div>
      </div>
    );
  }
}

export default StatLine;