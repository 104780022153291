const initState = {
  nbUpload: -1,
  errors: [],
  totalUpload: -1,
}

const uploadReducer = (state = initState, action) => {
  switch (action.type) {

    case 'START_UPLOAD':
      console.log('ACTION', action);
      return {
        ...state,
        nbUpload: 0,
        totalUpload: action.totUpload,
      }

    case 'END_UPLOAD_SESSION':
    console.log('ACTION', action);
    return {
      ...state,
      errors: [],
    }

    case 'UPLOAD_SUCCESSFUL':
      console.log('ACTION', action);
      if (state.nbUpload + 1 >= state.totalUpload) {
        return {
          ...state,
          nbUpload: -1,
          totalUpload: -1,
        }
      } else {
        return {
          ...state,
          nbUpload: state.nbUpload + 1,
        }
      }
      

    case 'UPLOAD_ERROR':
      console.log('ACTION', action);
      const newErrors = state.errors.splice();
      newErrors.push(action.file.name);
      if (state.nbUpload + 1 >= state.totalUpload) {
        return {
          ...state,
          nbUpload: -1,
          totUpload: -1,
          errors: newErrors,
        };
      } else {
        return {
          ...state,
          nbUpload: state.nbUpload + 1,
          errors: newErrors,
        }
      }
      
      
    default:
      return state;
  }
}

export default uploadReducer;