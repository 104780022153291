import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import './App.css'
import Nav from './component/nav/Nav'
import UploadReplay from './component/nav/UploadReplay';


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Route exact path='/' render={() => <Nav/>}/>
          <Route path='/upload' render={() => <UploadReplay/>}/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
