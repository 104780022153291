import M from 'materialize-css'
import { getSide } from '../../functions/gameAnalysis'
import {myTeamInit, opponentTeamInit, replaysInit} from '../../functions/initStore'

const initState = {
  myTeam: myTeamInit,
  opponentTeam: opponentTeamInit,
  replays: replaysInit,
  currentMatch: replaysInit[0],
  myTeamName: "Ex: Close to Death",
  opponentTeamName: "Ex: Multiple Smiles"
};


const filterReplays = (replays, members1, members2) => {
  if (members1.length === 0 || members2.length === 0) return replays;
  const filteredReplays = [];
  replays.forEach(replay => {
    if (getSide(replay, members1) !== getSide(replay, members2)) filteredReplays.push(replay);
  });
  return filteredReplays;
}




const teamReducer = (state = initState, action) => {
  switch (action.type) {

    case 'CREATE_TEAM':
      console.log('ACTION', action);
      const modalTeam = document.querySelector('#modal-create');
      M.Modal.getInstance(modalTeam).close();
      //const modalTeamSuccess = document.querySelector('#modal-create-success');
      //M.Modal.getInstance(modalTeamSuccess).open();
      return state;

    case 'CREATE_TEAM_ERROR':
      console.log('ACTION', action);
      const modalTeam1 = document.querySelector('#modal-create');
      M.Modal.getInstance(modalTeam1).close();
      //const modalTeamError = document.querySelector('#modal-create-error');
      //M.Modal.getInstance(modalTeamError).open();
      return state;
    
    case 'STATS_FOUND':
      console.log('ACTION', action);
      if (action.isMine) {
        return {
          ...state,
          myTeam: action.data,
          myTeamName: action.teamName,
        };
      } else {
        return {
          ...state,
          opponentTeam: action.data,
          opponentTeamName: action.teamName
        };
      }

    case 'REPLAYS_FOUND':
      console.log('ACTION', action);
      const members1 = state.myTeam ? state.myTeam.members : [];
      const members2 = state.opponentTeam ? state.opponentTeam.members : [];
      const filteredReplays = filterReplays(action.replays, members1, members2).sort((a,b) => a.timestamp < b.timestamp ? 1 : -1);
      return {
        ...state,
        replays: filteredReplays,
        currentMatch: filteredReplays[0],
      };
    
    case 'REPLAYS_NOT_FOUND':
      console.log('ACTION', action);
      return {
        ...state,
        replays: [],
        currentMatch: null,
      };
      
    case 'NO_STATS_FOUND':
      console.log('ACTION', action);
      return state;
    
    case 'GET_STATS_ERROR':
      console.log('ACTION', action);
      return state;
    
    case 'DESELECT_TEAM':
      console.log('ACTION', action);
      if (action.isMine) {
        return {
          ...state,
          myTeam: "",
          myTeamName: "",
        };
      } else {
        return {
          ...state,
          opponentTeam: "",
          opponentTeamName: "",
        }
      }
    
    case 'EMPTY_REPLAYS':
      console.log('ACTION', action);
      return {
        ...state,
        replays: [],
        currentMatch: null,
      }
      
    case 'SELECT_MATCH':
      console.log('ACTION', action);
      return {
        ...state,
        currentMatch: action.match,
      }
    case 'TEAM_DELETED':
      console.log('ACTION', action);
      return {
        ...state,
      }

    default:
      return state;
  }
}

export default teamReducer