import { combineReducers } from 'redux'
import authReducer from './authReducer'
import deathMapReducer from './deathMapReducer'
import teamReducer from './teamReducer';
import uploadReducer from './uploadReducer';
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  team: teamReducer,
  upload: uploadReducer,
  deathmap: deathMapReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
})

export default rootReducer