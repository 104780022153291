import appConfig from "../config/appConfig"
import { statToCat, statToDisplay, displayToStat } from './translators'
import { range, div, timeInInterval } from './hotsUtils'
import { isTopEndStat } from './translators'

export const getWinRatio = (wins, games) => {
  return games === 0 ?  0 : Math.round(((wins  / games)*100)*100)/100

}


export const normalizeTeamStats = (teamStats) => {
  teamStats.objectives.val = div(teamStats.objectives.val, teamStats.objectives.n);
  teamStats.earlyXPadv.val = div(teamStats.earlyXPadv.val, teamStats.earlyXPadv.n);
  teamStats.midXPadv.val = div(teamStats.midXPadv.val, teamStats.midXPadv.n);
  teamStats.lateXPadv.val = div(teamStats.lateXPadv.val, teamStats.lateXPadv.n);
  teamStats.firstBoss.val = div(teamStats.firstBoss.val, teamStats.firstBoss.n)*100;
  teamStats.firstCatapult.val = div(teamStats.firstCatapult.val, teamStats.firstCatapult.n)*100;
  teamStats.camps.val = div(teamStats.camps.val, teamStats.camps.n);

  if (teamStats.n > 1) {
    for (let stat in teamStats.stats) {
      if (teamStats.stats.hasOwnProperty(stat)) {
        teamStats.stats[stat] /= teamStats.n;
      }
    }
  } 
  
  for (let player in teamStats.playersPerfs) {
    if (teamStats.playersPerfs.hasOwnProperty(player) && teamStats.playersPerfs[player].n > 1) {
      teamStats.playersPerfs[player].perf = Math.round(teamStats.playersPerfs[player].perf/teamStats.playersPerfs[player].n);
      for (let perf in teamStats.playersPerfs[player].perfStats) {
        if (teamStats.playersPerfs[player].perfStats.hasOwnProperty(perf)) {
          teamStats.playersPerfs[player].perfStats[perf] /= teamStats.playersPerfs[player].n
        }
      }
      for (let perf in teamStats.playersPerfs[player].perfStatsBrut) {
        if (teamStats.playersPerfs[player].perfStatsBrut.hasOwnProperty(perf)) {
          teamStats.playersPerfs[player].perfStatsBrut[perf] /= teamStats.playersPerfs[player].n
        }
      }
    }
  }

  

  for (let hero in teamStats.heroPerfs) {
    if (teamStats.heroPerfs.hasOwnProperty(hero) && teamStats.heroPerfs[hero].n > 1) {
      teamStats.heroPerfs[hero].perf = Math.round(teamStats.heroPerfs[hero].perf/teamStats.heroPerfs[hero].n);
    }
  }

  for (let hero in teamStats.enemyPerfs) {
    if (teamStats.enemyPerfs.hasOwnProperty(hero) && teamStats.enemyPerfs[hero].n > 1) {
      teamStats.enemyPerfs[hero].perf = Math.round(teamStats.enemyPerfs[hero].perf/teamStats.enemyPerfs[hero].n);
    }
  }

  return teamStats;
}


export const scaleValues = (vals) => {
  const values = [...vals];
  if (values[0] === values[1]) return [50,50];
  if (values[0] === 0) return [10, 90];
  if (values[1] === 0) return [90, 10];
  if (values[0] < 0 && values[1] < 0) {
    const v0 = values[0];
    values[0] += Math.abs(v0+values[1]);
    values[1] += Math.abs(v0+values[1]);
  }
  else if (values[0] < 0 && values[0] < values[1]) return [10, 90];
  else if (values[1] < 0 && values[1] < values[0]) return [90, 10];
  
  const x = Math.max(Math.round(values[0]/(values[0]+values[1])*100), 10);
  return [x, 100-x];
}



// ----------------------------- Performance-related ------------------------------



export const extractBestPerfs = (perfStats) => {
  const perfs = [];
  perfs.push(["xp", perfStats.xp]);
  perfs.push(["outnumberedDeaths", perfStats.outnumberedDeaths]);
  const tmp = [];
  for (let key in perfStats) {
    if (key !== "xp" && key !== "outnumberedDeaths")
      tmp.push([key, perfStats[key]]);
  }
  const bestPerfs = tmp.sort((x, y) => x[1] < y[1] ? 1 : -1);
  const superBest = bestPerfs.slice(0, appConfig.N_PERF_PARAMS)
  return perfs.concat(superBest);
}


export const categorizePerfs = (bestPerfs, perfBruts) => {
  const categories = {};
  bestPerfs.forEach(([perfName, perfValue]) => {
    if (categories[statToCat(perfName)]) {
      categories[statToCat(perfName)].perf += Math.round(perfValue);
      categories[statToCat(perfName)].n += 1;
      categories[statToCat(perfName)].details.push([statToDisplay(perfName), perfBruts[perfName]]);
    } else {
      categories[statToCat(perfName)] = {
        catName: statToCat(perfName),
        perf: Math.round(perfValue),
        n: 1,
        details: [[statToDisplay(perfName), perfBruts[perfName]]],
      };
    }
  });
  return categories;
}

export const getBestPlayerPerfs = (teamPerfs) => {
  let id = null;
  let maxPerf = -1;
  for (let key in teamPerfs) {
    // perf already normalised
    if (teamPerfs[key].perf > maxPerf) {
      id = key;
      maxPerf = teamPerfs[key].perf;
    }
  }
  return [teamPerfs[id], id];
}


export const getBestHero = (heroes) => {
  let bestScore = -1;
  let bestHero = "";
  for (let hero in heroes) {
    if (heroes[hero] > bestScore) {
      bestScore = heroes[hero];
      bestHero = hero;
    }
  }
  return bestHero;
}


export const getBestHeroes = (heroPerfs) => {
  const heroesArray = [];
    for (let hero in heroPerfs) {
      heroesArray.push([hero, heroPerfs[hero].perf]);
    }
    return heroesArray.sort((a,b) => a[1] < b[1] ? 1 : -1);
}

export const mergeReplays = (replays1, replays2) => {
  if (!replays1) return replays2;
  if (!replays2) return replays1;
  if (replays1.length === 0 || replays2.length === 0) return [];
  return [...new Set(replays1.filter(value => replays2.includes(value)))];
}

export const isTeamPlaying = (replay, team) => {
  const teamMembers = team.members;
  const replayTeam1 = replay.players1;
  const replayTeam2 = replay.players2;
  let nMatchesTeam1 = 0;
  let nMatchesTeam2 = 0;
  teamMembers.forEach(member => {
    if (replayTeam1.includes(teamMembers)) {
      nMatchesTeam1 += 1;
    } else if (replayTeam2.includes(teamMembers)) {
      nMatchesTeam2 += 1;
    }
  });
  const hasPlayedTeam1 = nMatchesTeam1 === Math.min(teamMembers.length, 5);
  const hasPlayedTeam2 = nMatchesTeam2 === Math.min(teamMembers.length, 5);
  return hasPlayedTeam1 || hasPlayedTeam2;
}

export const extractReplayName = (replay) => {
  return replay.timestamp;
}

export const extractVictories = (replays, team, isTeam1P) => {
  const res = [0, 0];
  const teamIdx = isTeam1P ? 0 : 1;
  replays.forEach(replay => {
    const team1 = replay.players1.map(player => player.name);
    const isTeam1 = team1.includes(team.members[0]);
    const hasTeam1Won = replay.winner === 0;
    if ((isTeam1 && hasTeam1Won) || (!isTeam1 && !hasTeam1Won)) {
      res[teamIdx] += 1;
    } else {
      res[(teamIdx+1)%2] += 1;
    }
  });
  return res;
}


export const getSide = (replay, members) => {
  const names1 = replay.players1.map(player => player.name);
  const names2 = replay.players2.map(player => player.name);
  const matches1 = names1.filter(name => members.includes(name));
  const matches2 = names2.filter(name => members.includes(name));
  let side = -1;
  if (matches1.length === Math.min(members.length, 5)) side = 1;
  else if (matches2.length === Math.min(members.length, 5)) side = 2;
  else console.log('WE HAVE A PROBLEM');
  return side;
}

export const extractXPOverTime = (xpBreakdowns) => {
  const res = [];
  xpBreakdowns.forEach(xpBreakdown => {
    res.push(xpBreakdown.totalXP);
  });
  return decimate(res);
}

export const getBreakdownTimeStamps = (xpBreakdowns) => {
  const res = [];
  xpBreakdowns.forEach(xpBreakdown => {
    res.push(xpBreakdown.time.minutes);
  });
  return decimate(res);
}

const decimate = (xpBreakdowns) => {
  let filterIdx = [0];
  const period = Math.ceil((xpBreakdowns.length-2)/appConfig.N_XP_POINTS);
  filterIdx = filterIdx.concat(range(1, xpBreakdowns.length -1).filter(idx => {
    return (idx % period) === 0;
  }));
  filterIdx.push(xpBreakdowns.length -1);
  return filterIdx.map(filterId => {
    return xpBreakdowns[filterId];
  });
}

export const extractDeathInRange = (deaths, startTime, endTime) => {
  return deaths.filter(deathEvent => {
    const deathTime = deathEvent.time;
    return timeInInterval(deathTime, startTime, endTime);
  });
}

const addAvgToStat = (statsSummary, statKey, player, nPlayers) => {
  if (statsSummary[statKey].average || statsSummary[statKey].best === 0) {
    statsSummary[statKey].average += player.stats[statKey] /  nPlayers;
  } else {
    statsSummary[statKey].average = player.stats[statKey] /  nPlayers;
  }
}

const betterStat = (statKey, stat1, stat2) => {
  if (isTopEndStat(statKey)) {
    return stat2 > stat1;
  } else {
    return stat2 < stat1;
  }
}

const setBestToStat = (statsSummary, statKey, player) => {
  if (statsSummary[statKey].best || statsSummary[statKey].best === 0) {
    if (betterStat(statKey, statsSummary[statKey].best, player.stats[statKey])) {
      statsSummary[statKey].best = player.stats[statKey];
    }
  } else {
    statsSummary[statKey].best = player.stats[statKey];
  }
}

const setPlayerStat = (statsSummary, statKey, player) => {
  statsSummary[statKey].players[player.name] = player.stats[statKey];
}

export const extractStatsFromReplay = (replay) => {
  const statKeys = Object.keys(replay.players1[0].perfStats);
  const allPlayers = replay.players1.concat(replay.players2);
  const nPlayers = allPlayers.length;
  const statsSummary = {}
  statKeys.forEach(statKey => {
    statsSummary[statKey] = {
      players: {},
    };
    allPlayers.forEach(player => {
      addAvgToStat(statsSummary, statKey, player, nPlayers);
      setBestToStat(statsSummary, statKey, player);
      setPlayerStat(statsSummary, statKey, player);
    });
  });

  return statsSummary;
}

export const categorizeRelevantStats = (statsSummary, name, playerDetails) => {
  const catStats = {};
  const catKeys = Object.keys(playerDetails);
  catKeys.forEach(catKey => {
    catStats[playerDetails[catKey].catName] = [];
    playerDetails[catKey].details.forEach(statDetails => {
      const key = displayToStat(statDetails[0]);
      catStats[playerDetails[catKey].catName].push({
        name: statDetails[0],
        avg: statsSummary[key].average,
        best: statsSummary[key].best,
        player: statsSummary[key].players[name],
      })
    });
  });
  return catStats;
}