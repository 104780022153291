import React, {Component} from 'react';
import ModalTeamCreation from './ModalTeamCreation';
import M from 'materialize-css';
import UploadReplay from './UploadReplay';
import ModalProfile from './ModalProfile';
import ModalReview from './ModalReview';

class SignedInLinks extends Component {

  componentDidMount() {
    var modals = document.querySelectorAll('.modal');
    M.Modal.init(modals)
  }

  render () {
    return (
      <div className="col s12 m10 l10">
        <div className="row no-space">
          <div className="col s3 m2 l1 btn waves-effect waves-light hots-button modal-trigger pad" data-target="modal-profile">
            <i className="material-icons iconColor-light">account_circle</i>
            {/*<span className="hots-text" >Create team</span>*/}
          </div>
          <div className="col s3 m2 l1 offset-s1 btn waves-effect waves-light hots-button modal-trigger pad" data-target="modal-create">
            <i className="material-icons iconColor-light">person_add </i>
            {/*<span className="hots-text" >Create team</span>*/}
          </div>
          <div className="col s3 m2 l1 offset-s1 btn waves-effect waves-light hots-button modal-trigger pad" data-target="modal-upload">
            <i className="material-icons iconColor-light">cloud_upload</i>
            {/*<span className="hots-text" >Upload replay</span>*/}
          </div>
          <div className="col s12 m2 l2 offset-l4 btn waves-effect waves-light modal-trigger pad fb hvr-bounce-in" data-target="modal-review">
          <i className="material-icons iconColor">rate_review</i>
            {/*<span className="hots-text" >Evaluate</span>*/}
          </div>
          <div to="/" className="col s12 m3 l2 btn waves-effect waves-light hots-button pad" onClick={this.props.handleSignOut}>
            <span className="hots-text">Logout</span>
          </div>
          <div className="modal hots-modal-team rounded-modal" id="modal-create">
            <ModalTeamCreation/>
          </div>
          <div className="modal hots-modal-profile rounded-modal" id="modal-profile">
            <ModalProfile resetState={this.props.resetState}/>
          </div>
          <div className="modal review-modal rounded-modal" id="modal-review">
            <ModalReview/>
          </div>
          <div className="modal hots-modal" id="modal-create-error">
            <div className="container">
              <span>An error occured while creating the team ... please try again later!</span>
            </div>
          </div>
          <div className="modal hots-modal" id="modal-create-success">
            <div className="container">
              <i className="material-icons large green">done</i>
              <span>Your team has been successfully added</span>
            </div>
          </div>
          <div className="modal hots-modal-upload rounded-modal" id="modal-upload">
            <UploadReplay/>
          </div>
        </div>
      </div>
    )
  }
}



export default SignedInLinks;