import React, { Component } from 'react';
import { concatTeams } from '../../functions/hotsUtils'
import { deleteTeam } from '../../store/actions/teamActions'
import { connect } from 'react-redux';
import M from 'materialize-css'
import {dateToString} from '../../functions/hotsUtils'
import Lineup from '../layout/Lineup'

class ModalProfile extends Component {

  tooltipIds = [];


  makeId(name, timestamp) {
    const timestampList = timestamp.split(' ');
    const [hours, minutes, seconds] = timestampList[1].split(':');
    return name+timestampList[0]+hours+minutes+seconds;
  }

  playersToTooltip(players) {
    let res = "<ul>"
    players.forEach(player => {
      res += "<li><span>"+player.name+": "+player.character+"</span></li>"
    })
    res += "</ul>"
    return res
  }


  componentDidUpdate() {
    this.tooltipIds.forEach(id => {
      const tooltip = document.getElementById(id);
      M.Tooltip.init(tooltip, null);
    });
    for (let replayName in this.props.myReplays) {
      const replay = this.props.myReplays[replayName];
      const tooltip1 = document.querySelector('#'+this.makeId(replay.name, replay.replayTimestamp)+"-1");
      const tooltipOptions1 = {
        position: "top",
        html: this.playersToTooltip(replay.players1),
      }
      M.Tooltip.init(tooltip1, tooltipOptions1);

      const tooltip2 = document.querySelector('#'+this.makeId(replay.name, replay.replayTimestamp)+"-2");
      const tooltipOptions2 = {
        position: "bottom",
        html: this.playersToTooltip(replay.players2),
      }
      M.Tooltip.init(tooltip2, tooltipOptions2);
    }
  }

  componentDidMount() {
    var elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, null);   
  }

  renderMembers = (team, teamId) => {
    const res = [];
    res.push(<ul key={teamId+"ul-members"}>{team.members.map(member => {
      return (<li key={teamId+member}><span className="hots-text">{member}</span></li>)
    })}</ul>);
    return res;
  }

  handleDelete = (teamId, teamName) => {
    this.props.deleteTeam(teamId, teamName);
    if ((this.props.myTeam !== '' && this.props.myTeams === teamName)||
       (this.props.opponentTeam !== '' && this.props.opponentTeam === teamName)) {
        this.props.resetState();
      }
  }

  renderMyTeams = () => {
    const res = [];
    this.tooltipIds = [];
    const teams = this.props.myTeams ? Object.keys(this.props.myTeams): [];
    res.push(<h4 className="hots-text txt-medium "key={"my-team-key"}>My Teams</h4>)
    res.push(<ul key="my-team-collapsible" className='collapsible  scroll-profile'>{
      teams.map(teamId => {
        this.tooltipIds.push(teamId);
        const team = this.props.myTeams[teamId];
        const deleteButton = team.user !=="DEFAULT" ?
          <button id={teamId} className="btn-floating btn-small no-padding-button tooltipped button-blue" data-position="top"
           data-tooltip="delete team" onClick={() => this.handleDelete(teamId, team.name)}>
            <i className="material-icons no-m-right">close</i>
          </button>  
          : 
          <div></div>
        return (
        <li key={teamId}>
          <div className="collapsible-header">
            <div className="row no-space-at-all">
              <i className="material-icons col s1 icon-color">group</i>
              <span className="col s7 hots-text">{team.name}</span>
              <div className="col s3">
                {deleteButton}
              </div>
            </div>
          </div>
          <div className="collapsible-body">{this.renderMembers(team, teamId)}</div>
        </li>
        )
      })
    }</ul>);
    return res;
  } 

  renderReplayInfo = (replayName, replay) => {
    const heroes1 = replay.players1.map(player => [player.character]);
    const heroes2 = replay.players2.map(player => [player.character]);
    return (
      <ul key={replayName+"ul-info"}>
        <li key={replayName+"map"}><span className="hots-text">{"Map: "+replay.map}</span></li>
        <li key={replayName+"time"}><span className="hots-text">{"Time: "+replay.replayTimestamp}</span></li>
        <li key={replayName+"uptime"}><span className="hots-text">{"Uploaded at: "+dateToString(replay.uploadTimestamp.toDate())}</span></li>
        <li key={replayName+"team1"} className="tooltipped" id={this.makeId(replay.name, replay.replayTimestamp)+"-1"}><Lineup heroes={heroes1}/></li>
        <li key={replayName+"team2"} className="tooltipped" id={this.makeId(replay.name, replay.replayTimestamp)+"-2"}><Lineup heroes={heroes2}/></li>
      </ul>
    )
  }

  renderMyReplays = () => {
    const res = [];
    const replayNames = this.props.myReplays ? Object.keys(this.props.myReplays): [];
    res.push(<h4 className="hots-text txt-medium" key="my-replay-key">My Replays</h4>)
    res.push(<ul key="my-replay-collapsible" className='collapsible scroll-profile'>{replayNames.map(replayName => {
      return (
        <li key={replayName}>
          <div className="collapsible-header"><i className="material-icons icon-color">filter_drama</i><span className="hots-text">{replayName}</span></div>
          <div className="collapsible-body">{this.renderReplayInfo(replayName, this.props.myReplays[replayName])}</div>
        </li>
      )
    })}</ul>);
    if (replayNames.length === 0) {
      res.push(<h5 key="upload-missing" className="hots-text big-margin-top">You haven't uploaded any replay.</h5>)
    }
    return res
  }

  render() {
    return (
      <div className="pad-mod-all">
        <div className="row">
        </div>
        <div className="row">
          <div className="col s12 m6 l6">
            {this.renderMyTeams()}
          </div>
          <div className="col s12 m6 l6">
            {this.renderMyReplays()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myTeams:  concatTeams(state.firestore.data["myTeams"], state.firestore.data["defaultTeams"]),
    myTeam: state.team.myTeam,
    opponentTeam: state.team.opponentTeam,
    myReplays: state.firestore.data["myReplays"],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteTeam: (teamId, teamName) => dispatch(deleteTeam(teamId, teamName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProfile);