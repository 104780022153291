import React, { Component } from 'react';
import Diamond from './Diamond';
import Perf from './Perf'
import {heroToIcon} from '../../functions/translators'
import M from 'materialize-css'



class LineupH2H extends Component {

  modalRefs  = [null, null, null, null, null];

  componentDidMount() {
    for (let idx = 0; idx < 5; idx++){
      const modal = document.querySelector("#modal-perf"+this.props.side+idx);
      const options = {
        onOpenEnd: () => {
          this.modalRefs[idx].trigger();
        },
        onCloseEnd: () => {
          this.modalRefs[idx].trigger();
        }
      };
      M.Modal.init(modal, options);
    }
  }

  render() {
    return ( 
      <div className="row row-smallestMargin">
        <div className="row no-space-at-all">
          <div data-target={"modal-perf"+this.props.side+0} className="modal-trigger">
            <Diamond hero={heroToIcon(this.props.teamMembers[0].character)} cursor="pointer"/>
          </div>
          <div data-target={"modal-perf"+this.props.side+1} className="modal-trigger">
            <Diamond hero={heroToIcon(this.props.teamMembers[1].character)} cursor="pointer"/>
          </div>
          <div data-target={"modal-perf"+this.props.side+2} className="modal-trigger">
            <Diamond hero={heroToIcon(this.props.teamMembers[2].character)} cursor="pointer"/>
          </div>
          <div data-target={"modal-perf"+this.props.side+3} className="modal-trigger">
            <Diamond hero={heroToIcon(this.props.teamMembers[3].character)} cursor="pointer"/>
          </div>
          <div data-target={"modal-perf"+this.props.side+4} className="modal-trigger">
            <Diamond hero={heroToIcon(this.props.teamMembers[4].character)}cursor="pointer"/>
          </div>
        </div>
        <div className="row no-space-at-all">
          <span className="col s5th hots-text">{this.props.teamMembers[0].perf}</span>
          <span className="col s5th hots-text">{this.props.teamMembers[1].perf}</span>
          <span className="col s5th hots-text">{this.props.teamMembers[2].perf}</span>
          <span className="col s5th hots-text">{this.props.teamMembers[3].perf}</span>
          <span className="col s5th hots-text">{this.props.teamMembers[4].perf}</span>
        </div>
        <div id={"modal-perf"+this.props.side+0} className="modal perf-modal">
          <Perf ref={(reference) => this.modalRefs[0] = reference } titleModal={"Player Performance"} 
            playerName={this.props.teamMembers[0].name} perfs={this.props.teamMembers[0]}
            summary={this.props.summary} character={this.props.teamMembers[0].character} singlePerf={true} />
        </div>
        <div id={"modal-perf"+this.props.side+1} className="modal perf-modal">
          <Perf ref={(reference) => this.modalRefs[1] = reference } titleModal={"Player Performance"}
            playerName={this.props.teamMembers[1].name} perfs={this.props.teamMembers[1]}
            summary={this.props.summary} character={this.props.teamMembers[1].character} singlePerf={true}/>
        </div>
        <div id={"modal-perf"+this.props.side+2} className="modal perf-modal">
          <Perf ref={(reference) => this.modalRefs[2] = reference } titleModal={"Player Performance"} 
            playerName={this.props.teamMembers[2].name} perfs={this.props.teamMembers[2]}
            summary={this.props.summary} character={this.props.teamMembers[2].character} singlePerf={true} />
        </div>
        <div id={"modal-perf"+this.props.side+3} className="modal perf-modal">
          <Perf ref={(reference) => this.modalRefs[3] = reference } titleModal={"Player Performance"} 
            playerName={this.props.teamMembers[3].name} perfs={this.props.teamMembers[3]}
            summary={this.props.summary} character={this.props.teamMembers[3].character} singlePerf={true} />
        </div>
        <div id={"modal-perf"+this.props.side+4} className="modal perf-modal">
          <Perf ref={(reference) => this.modalRefs[4] = reference } titleModal={"Player Performance"} 
            playerName={this.props.teamMembers[4].name} perfs={this.props.teamMembers[4]}
            summary={this.props.summary} character={this.props.teamMembers[4].character} singlePerf={true} />
        </div>
      </div>
    );
  }
}





export default LineupH2H;