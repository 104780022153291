import React, { Component } from 'react'
import ModalLogin from '../auth/ModalLogin';
import ModalSignup from '../auth/ModalSignup';
import M from 'materialize-css';


class SignedOutLinks extends Component {

  componentDidMount() {
    var modals = document.querySelectorAll('.modal');
    M.Modal.init(modals)
  }

  render() {
    return (
      <div className="row no-space">
        <div className="col s0 m4 l6"></div>
        <div className="col s12 m3 l2">
          <div className="btn waves-effect waves-light hots-button modal-trigger drop-button pad" data-target="modal-login">
            <span className="hots-text" >Log in</span>
          </div>
        </div>
        <div className="col s12 m3 l2">
          <div className="btn waves-effect waves-light hots-button modal-trigger drop-button pad" data-target="modal-signup">
            <span className="hots-text" >Sign up</span>
          </div>
        </div>

        <div className="modal hots-modal rounded-modal" id="modal-login">
          <ModalLogin/>
        </div>

        <div className="modal hots-modal rounded-modal" id="modal-signup">
          <ModalSignup/>
        </div>
      </div>
    )
  }
}

export default SignedOutLinks;