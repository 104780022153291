import React, { Component } from 'react';
import './TeamBox.css'
import '../nav/Nav.css'
import StatLine from './StatLine'
import Lineup from '../layout/Lineup';
import { getBestPlayerPerfs, getBestHero, getBestHeroes } from '../../functions/gameAnalysis'
import M from 'materialize-css'
import Perf from '../layout/Perf'
import { heroToIcon } from '../../functions/translators';

class TeamBox extends Component {

  state = {}
  height = 40
  perfModal = {}

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    const modal = document.querySelector('#modal-best-player-perf'+this.props.side);
    const tooltip = document.querySelector('#tooltipped-best'+this.props.side);
    const modalOptions = {
      onOpenEnd: () => {
        M.Tooltip.getInstance(tooltip).close();
        this.perfModal.trigger();
      },
      onCloseEnd: () => {
        this.perfModal.trigger();
      }
    };
    const tooltipOptions = {
      position: "bottom",
      html: "Click on the hero to see more details!",
    }
    M.Modal.init(modal, modalOptions);
    M.Tooltip.init(tooltip, tooltipOptions);

    const tooltipPerf = document.querySelector("#perf-tooltip"+this.props.side);
    const options = {
      position: "top",
      html: '<div style="max-width:500px;text-align:left"><span>Best player, best heroes and nemeses are determined using a feature we called "performance". This performance depends on how the player has performed in-game and is based on the following characteristics:<span><ul><li><span style="font-weight:bold">Tactic</span>: xp contribution, outnumbered deaths, mercenary captures, map award and MVP</li><li><span style="font-weight:bold">Takedowns</span>: kills, deaths and assists</li><li><span style="font-weight:bold">Damage</span>: hero damage, teamfight hero damage and siege damage</li><li><span style="font-weight:bold">Heal</span>: heal, teamfight heal, clutch heals</li><li><span style="font-weight:bold">Utility</span>: crowd control and protections</li><li><span style="font-weight:bold">Tank</span>: damage taken and teamfight damage taken</li></ul></div>'
    };
    M.Tooltip.init(tooltipPerf, options);
    this.handleResize();
  }

  componentDidUpdate() {
    this.handleResize();
  }

  handleResize = () => {
    let height = 33;
    const offSet1 = document.getElementById('img-bestopponentTeam').offsetHeight;
    const offSet2 = document.getElementById('img-bestmyTeam').offsetHeight;
    if (offSet1 !== 0) {
      height = offSet1
    } else if (offSet2 !== 0) {
      height = offSet2;
    }
    document.getElementById('best-playerName-colmyTeam').style.height = height+"px";
    document.getElementById('best-playerName-colopponentTeam').style.height = height+"px";
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const killsWin = this.props.team.stats.kills > this.props.enemy.stats.kills ? "-win" : "";
    const assistsWin = this.props.team.stats.assists > this.props.enemy.stats.assists ? "-win" : "";
    const deathsWin = this.props.team.stats.deaths < this.props.enemy.stats.deaths ? "-win" : "";
    const dmgWin = this.props.team.stats.dmg > this.props.enemy.stats.dmg ? "-win" : "";
    const healWin = this.props.team.stats.heal > this.props.enemy.stats.heal ? "-win" : "";
    const siegeWin = this.props.team.stats.siege > this.props.enemy.stats.siege ? "-win" : "";
    const [bestPlayerPerfs, bestPlayerName] = getBestPlayerPerfs(this.props.team.playersPerfs);
    const bestHero = getBestHero(bestPlayerPerfs.heroes);
    const bestHeroes = getBestHeroes(this.props.team.heroPerfs);
    const nemeses = getBestHeroes(this.props.team.enemyPerfs);
    const el = document.getElementById('img-best'+this.props.side);
    if (el) {
      this.height = el.offsetHeight;
    }
    return (
      <div className="row row-smallerMargin">
        <div className="col s12 teambox">

          {/* BASIC STATS */}
          <StatLine data={{
            img: ["/resources/icons/kill.png", "/resources/icons/hero_damage.png"],
            stat: [Math.round(this.props.team.stats.kills*10)/10, Math.round(this.props.team.stats.dmg/1000) + "k"],
            win: [killsWin, dmgWin]
          }}/>
          <StatLine data={{
            img: ["/resources/icons/assist.png", "/resources/icons/healing.png"],
            stat: [Math.round(this.props.team.stats.assists*10)/10, Math.round(this.props.team.stats.heal/1000) + "k"],
            win: [assistsWin, healWin]
          }}/>
          <StatLine data={{
            img: ["/resources/icons/dead.png", "/resources/icons/struct_damage.png"],
            stat: [Math.round(this.props.team.stats.deaths*10)/10, Math.round(this.props.team.stats.siege/1000) + "k"],
            win: [deathsWin, siegeWin]
          }}/>

          {/* BEST PLAYER */}
          <div className="row row-smallestMargin">
            <span className="col s12 hots-text margin-bottom">Best player</span>
            <div id={"best-playerName-col"+this.props.side} className="col s6 m6 l6 hero-icon valign-wrapper">
              <span className="hots-text text-shadow best-player truncate span-fill2">{bestPlayerName}</span>
            </div>
            <div id={"tooltipped-best"+this.props.side} data-target={"modal-best-player-perf"+this.props.side} className="col s2 m2 l2 small-pad tooltipped modal-trigger hover-hero-pic-2">
                <img id={"img-best"+this.props.side} src={heroToIcon(bestHero)} alt="" className="hero-icon-small center pointable"/>
                <div className="overlay pointer"></div>
            </div>
            <div className="col s4 m4 l4">
              <a id={"perf-tooltip"+this.props.side} className="btn-floating btn-small tooltipped hots-tooltip center-align no-background tooltip-border" href="# ">
                <i><span className="hots-text no-left-pad">?</span></i>
              </a>
            </div>
            <div className="row no-space-at-all">
              <div className="col s6"></div>
              <span className="col s2 hots-text">{bestPlayerPerfs.perf}</span>
              <div className="col s4"></div>
            </div>
          </div>


          {/* BEST HEROES */}
          <div className="row row-smallestMargin">
            <span className="col s12 hots-text pad-bottom">Best heroes</span>
            <Lineup heroes={bestHeroes}/>
          </div>



          {/* NEMESES */}
          <div className="row row-smallestMargin">
            <span className="col s12 hots-text pad-bottom">Nemeses</span>
            <Lineup heroes={nemeses}/>
          </div>

        </div> 
        <div id={"modal-best-player-perf"+this.props.side} className="modal perf-modal">
          <Perf ref={(reference) => this.perfModal = reference } titleModal={"Player Performance"}
             playerName={bestPlayerName} perfs={bestPlayerPerfs} singlePerf={false} />
        </div>
      </div>
    );
  }
}




export default TeamBox;