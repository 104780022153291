import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import '@firebase/storage'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDsjqLD2L1IXhKaT_an1TIlauWpRUHF1LY",
  authDomain: "hotstats-4545e.firebaseapp.com",
  databaseURL: "https://hotstats-4545e.firebaseio.com",
  projectId: "hotstats-4545e",
  storageBucket: "hotstats-4545e",
  messagingSenderId: "712669273912"
};
firebase.initializeApp(config);
firebase.firestore().settings({})

export default firebase;