import React, { Component } from 'react'
import './Nav.css'
import SignedOutLinks from './SignedOutLinks';
import SignedInLinks from './SignedInLinks';
import { connect } from 'react-redux';
import Overview from '../overview/Overview';
import HeadToHead from '../head-to-head/HeadToHead';
import Dropdown from '../layout/Dropdown';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { signOut } from '../../store/actions/authActions';
import { getTeamStats, deselectTeam, tryFetchReplays } from '../../store/actions/teamActions'

class Nav extends Component {
  
  state = {
    active: window.location.href.split('#').length > 1 ? window.location.href.split('#')[1] : "overview"
  }
  teams = [{name: "Ex: Close to Death", members:["CookieMan", "Hooberman", "Keenan", "BloodSplat", "RedRobot"]}, 
           {name: "Ex: Multiple Smiles", members:["Diesel", "Battery", "Caesarsalad", "HaoNguyen", "Liam", "healer"]}]
  resetDropName = [null, null];
  overview = "overview";
  headtohead = "headtohead";
  modified = true;

  changeTab = (e) => {
    const isHead2Head = e.target.id === this.headtohead;
    if (isHead2Head) {
      if (this.modified) {
        if (this.teams[0].name !== "Select Team" && this.teams[1].name === "Select Team"){
          this.props.getTeamStats(this.teams[0], true, true);
        } else if (this.teams[1].name !== "Select Team" && this.teams[0].name === "Select Team") {
          this.props.getTeamStats(this.teams[1], false, true);
        } else {  
          this.props.tryFetchReplays();
        }
      }
    }

    this.modified = false;
    this.setState({
      active: e.target.id,
    });
  }

  setResetDropName = (func, idx) => {
    this.resetDropName[idx] = func;
  }

  resetState = () => {
    this.teams = [{name: "Select Team",}, {name: "Select Team",}];
    if (this.resetDropName[0])
      this.resetDropName[0]();
    if (this.resetDropName[1])
      this.resetDropName[1]();
    this.props.deselectTeam(true);
    this.props.deselectTeam(false);
    this.props.emptyReplays();
    this.modified = true;
  }

  handleSignOut = () => {
    this.resetState();
    this.props.signOut();
  }

  handleSelectTeam = (team, idx) => {
    const isMine = idx === 0;
    if (team == null) {
      this.teams[idx] = {name: "Select Team"}
      this.props.deselectTeam(isMine);
    } else {
      const newTeams = [...this.teams];
      newTeams[idx] = team;
      const name1 = newTeams[0].name;
      const name2 = newTeams[1].name;
      // At least one new team
      if (name1 !== this.teams[0].name || name2 !== this.teams[1].name) {
        if (this.state.active === this.headtohead) {
          this.props.getTeamStats(newTeams[idx], isMine, true);
        } else {
          this.modified = true;
          if (name1 !== "Select Team" && name2 !== "Select Team") {
            if (this.teams[0].name !== "Select Team" && this.teams[1].name !== "Select Team") {
              this.props.getTeamStats(newTeams[idx], isMine, false);
            } else {
              this.props.getTeamStats(newTeams[0], true, false);
              this.props.getTeamStats(newTeams[1], false, false);
            }
          }
        }
      }
      this.teams = newTeams;
    }
  }

  render() {
    window.history.replaceState("", "", "#"+this.state.active);
    const tab = this.state.active === this.overview ? <Overview/> : <HeadToHead/>;
    const tabActiveOverview = this.state.active === this.overview ? "active-tab" : "";
    const tabActiveHeadToHead = this.state.active === this.headtohead ? "active-tab" : "";
    const textShadowOverview = this.state.active === this.overview ? "text-shadow" : "";
    const textShadowHeadToHead = this.state.active === this.headtohead ? "text-shadow" : "";
    const links = this.props.uid ? <SignedInLinks resetState={this.resetState} handleSignOut={this.handleSignOut}/> : <SignedOutLinks/>;
    return (
      <div className="container">
        <div className="row row-smallestMargin">
          <div className="col s12 m2 l2">
            <img alt="Logo" className="small-img" src="/resources/images/logo_test.png"/>
          </div>
          {links}
        </div>
        <div className="row row-smallestMargin">
          <div className="col s12 m6 l5 pad-bottom">
            <Dropdown setDrop={this.setResetDropName} dropName={this.teams[0].name} handleSelectTeam={this.handleSelectTeam} teamId={0}/>
          </div>
          <div className="col s0 m0 l2"></div>
          <div className="col s12 m6 l5 pad-bottom">
            <Dropdown setDrop={this.setResetDropName} dropName={this.teams[1].name} handleSelectTeam={this.handleSelectTeam} teamId={1}/>
          </div>
        </div>
        <hr className="hots-hline"></hr>
        <div className="row nav-tabs">
          <div className={"col s6 center-col nav-tab " + tabActiveOverview} >
            <span className={"hots-text txt-medium span-fill col s12 "+textShadowOverview} onClick={this.changeTab} id={this.overview}>
              Overview
            </span>
          </div>
          <div className={"col s6 center-col nav-tab "+ tabActiveHeadToHead}>
            <span className={"hots-text txt-medium span-fill  col s12"+textShadowHeadToHead} onClick={this.changeTab} id={this.headtohead}>
              Head-2-Head
            </span>
          </div>
        </div>
        <hr className="hots-hline"></hr>
        {tab}
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    getTeamStats: (team, isMine, should) => dispatch(getTeamStats(team, isMine, should)),
    deselectTeam: (isMine) => dispatch(deselectTeam(isMine)),
    tryFetchReplays: () => dispatch(tryFetchReplays()),
    emptyReplays: () => dispatch({type:'EMPTY_REPLAYS'}),
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.uid) return [
      {
        collection: 'teams' ,
        where: [
          ['user', '==', "DEFAULT"],
        ],
        storeAs: "defaultTeams", 
      }
    ];
    return [
      {
        collection: 'teams' ,
        where: [
          ['user', '==', "DEFAULT"],
        ],
        storeAs: "defaultTeams", 
      },
      {
        collection: 'teams' ,
        where: [
          ['user', '==', props.uid],
        ],
        storeAs: "myTeams", 
      },
      {
        collection: 'playersReplays' ,
        doc: props.uid,
        storeAs: "myReplays", 
      },
    ]
  })
)(Nav);