import M from 'materialize-css'

const initState = {
  authError: null,
}

const authReducer = (state = initState, action) => {

  switch (action.type) {

    case 'LOGIN_SUCCESS':
      console.log('ACTION', action);
      const modalLogin = document.querySelector('#modal-login');
      M.Modal.getInstance(modalLogin).close();
      window.location.reload();
      return {
        ...state,
        authError: null
      }

    case 'LOGIN_ERROR':
      console.log('ACTION', action);
      return {
        ...state,
        authError: action.err.message
      }

    case 'SIGNOUT_SUCCESS':
      console.log('ACTION', action);
      return state

    case 'SIGNUP_SUCCESS':
      console.log('ACTION', action);
      const modalSignup = document.querySelector('#modal-signup');
      M.Modal.getInstance(modalSignup).close();
      return {
        ...state,
        authError: null
      }
    
    case 'SIGNUP_ERROR':
      console.log('ACTION', action);
      return {
        ...state,
        authError: action.err.message
      }
    
    case 'REQUEST_REVIEW_SUCCESS':
      console.log('ACTION', action);
      return state;
    
    case 'REQUEST_REVIEW_ERROR':
      console.log('ACTION', action);
      return state;
      
    default:
      return state;
  }
}

export default authReducer