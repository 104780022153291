var appConfig = {
  MAX_MEMBERS : 7,
  LIMIT_AUTOCOMPLETE: 3,
  MIN_CHAR_AUTOCOMPLETE: 3,
  MAX_TEAM_NAME_LENGTH: 28,
  MAX_MEMBERS_NAME_LENGTH: 15,
  N_PERF_PARAMS: 6,
  N_XP_POINTS: 8,
  MAX_REPLAYS: 200,
}

export default appConfig;