import React, { Component } from 'react';
import { heroToIcon } from '../../functions/translators'

class HeroStats extends Component {

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
  
  componentDidUpdate() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const height =document.getElementById("stats-icon0").offsetHeight;
    const width = window.innerWidth;
    let newDeviceScreen = "large";
    if (width < 350) {
      newDeviceScreen = "extra-super-small"
    }else if (width < 400) {
      newDeviceScreen = "extra-small";
    } else if (width < 500) {
      newDeviceScreen = "very-small";
    } else if (width < 601) {
      newDeviceScreen = "small";
    } else if (width < 790) {
      newDeviceScreen = "mi-small"
    } else if (width < 993) {
      newDeviceScreen = "medium";
    } else if (width > 1300) {
      newDeviceScreen = "top-large";
    }
    if (height === 21 && newDeviceScreen === "small") {
      this.resizeCols(84);
    } else if (height <= 23 && newDeviceScreen === "medium") {
      this.resizeCols(145);
    } else if (height <= 23 && newDeviceScreen === "mi-small") {
      this.resizeCols(110);
    } else if (height <= 23 && newDeviceScreen === "very-small"){
      this.resizeCols(65);
    } else if (height <= 23 && newDeviceScreen === "extra-small"){
      this.resizeCols(45);
    } else if (height <= 23 && newDeviceScreen === "extra-super-small") {
      this.resizeCols(38);
    } else if (height <= 23 && newDeviceScreen === "top-large") { 
      this.resizeCols(32);
    } else {
      this.resizeCols(height);
    }


  }

  resizeCols = (height) => {
    document.getElementById("stats-col"+this.props.idCol).style.height = height+"px";
  }

  render() {
    const winsStyles = this.props.wins.map(win => win ? "-win" : "");
    const border = this.props.border ? " bottom-border" : ""
    return (
      <div className={"row no-space-at-all"+border}>
        <div id={"stats-icon"+this.props.idCol} className="col s2">
          <img  src={heroToIcon(this.props.hero)} alt="" className="hero-icon-small"/>
        </div>
        <div id={"stats-col"+this.props.idCol} className="col s10 valign-wrapper ">
          <div className="col s2 v-center">
            <span className={"hots-text game-stats"+winsStyles[0]}>{this.props.stats.kills}</span>
          </div>
          <div className="col s2 v-center">
            <span className={"hots-text game-stats"+winsStyles[1]}>{this.props.stats.assists}</span>
          </div>
          <div className="col s2 v-center">
            <span className={"hots-text game-stats"+winsStyles[2]}>{this.props.stats.deaths}</span>
          </div>
          <div className="col s2 v-center">
            <span className={"hots-text game-stats"+winsStyles[3]}>{Math.round(this.props.stats.damage/1000)+"k"}</span>
          </div>
          <div className="col s2 v-center">
            <span className={"hots-text game-stats"+winsStyles[4]}>{Math.round(this.props.stats.heal/1000)+"k"}</span>
          </div>
          <div className="col s2 v-center">
            <span className={"hots-text game-stats"+winsStyles[5]}>{Math.round(this.props.stats.siege/1000)+"k"}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroStats;