import React, { Component } from 'react';
import Bar from '../layout/Bar'
import '../layout/Bar.css'

class BarComp extends Component {
  render() {
    return (
      <div>
        <Bar values={[this.props.team1.firstBoss.val, this.props.team2.firstBoss.val]} title="First boss (%)"/>
        <Bar values={[this.props.team1.firstCatapult.val, this.props.team2.firstCatapult.val]} title="First catapult (%)"/>
        <Bar values={[this.props.team1.objectives.val, this.props.team2.objectives.val]} title="Objective win (%)"/>
        <Bar values={[this.props.team1.camps.val, this.props.team2.camps.val]} title="Camps by game"/>
        <Bar values={[this.props.team1.earlyXPadv.val, this.props.team2.earlyXPadv.val]} title="Early XP advantage (at 6 min)"/>
        <Bar values={[this.props.team1.midXPadv.val, this.props.team2.midXPadv.val]} title="Mid XP advantage (at 12 min)"/>
        <Bar values={[this.props.team1.lateXPadv.val, this.props.team2.lateXPadv.val]} title="Late XP advantage (at the end)"/>
      </div>
    );
  }
}

export default BarComp;