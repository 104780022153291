import React, { Component } from 'react';
import { Bubble } from 'react-chartjs-2';
import { mapNameToData } from '../../functions/translators';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { timeToSec, secToTime, secToTimestamp } from '../../functions/hotsUtils';
import { extractDeathInRange } from '../../functions/gameAnalysis'
import { connect } from 'react-redux';
import { updateRange } from '../../store/actions/deathmapActions'
import M from 'materialize-css'


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class Deathmap extends Component {

  handleTimeTemp = null;
  rangeRef = null;
  deaths1 = [];
  deaths2 = [];
  radius = 7;
  radiusTeam = 4*this.radius;
  ratio = 1.9;
  objectivesMarks = {}
  objectivesInfo = []


  updateDot = () => {
    const elems = document.querySelectorAll(".rc-slider-dot");
    elems.forEach((elem, idx) => {
      elem.setAttribute("className", "tooltipped");
      const color = this.objectivesInfo[idx].dotColor;
      elem.style.backgroundColor = color;
      const timestamp = secToTime(this.objectivesInfo[idx].timestamp)
      const min_s = timestamp.minutes > 9 ? timestamp.minutes : "0"+timestamp.minutes;
      const sec_s = timestamp.secs > 9 ? timestamp.secs : "0"+timestamp.secs;
      const text = "<span class = left>"+timestamp.hours+":"+min_s+":"+sec_s+"</span> <br/><span class = left>"
      +this.objectivesInfo[idx].label+"</span><br/> <span class = left>"+this.objectivesInfo[idx].teamName+"</span>";
      const option = {
        position: "top",
        html: text,
      }
      M.Tooltip.init(elem, option);
    });
  } 

  componentDidMount() {
    this.updateDot();
  }

  componentDidUpdate() {
    this.updateDot();
  }

  getTimeLabel = (time) => {
    let timeLabel = time.hours+":";
    const minutes = time.minutes > 9 ? time.minutes.toString() : "0"+time.minutes;
    const seconds = time.seconds > 9 ? time.seconds.toString() : "0"+time.seconds;
    timeLabel += minutes + ":" + seconds;
    return timeLabel;
  }

  handleLabel = (tooltipItem, data) => {
    return "";
  }

  handleTitle = (tooltipItem, data) => {
    const death = tooltipItem[0].datasetIndex === 0 ? 
      this.deaths1[tooltipItem[0].index] : this.deaths2[tooltipItem[0].index];
    if (death.start) {
      return "STARTING POSITION"
    }
    return "DEATH"
  }

  handleOnChange = (handleTime) => {
    this.handleTimeTemp = handleTime;
    this.setState({});
  }

  handleLabelArray = (tooltipItem, data) => {
    if (tooltipItem.length === 0) return "";
    let label = "";
    const death = tooltipItem[0].datasetIndex === 0 ? 
      this.deaths1[tooltipItem[0].index] : this.deaths2[tooltipItem[0].index];
    if (death.start) {
      return tooltipItem[0].datasetIndex === 0 ? this.props.teamName1 : this.props.teamName2;
    }
    else {
      label += "victim: " + death.victim + "\n";
      label += "killer: " + death.killer + "\n";
      label += "time: " + this.getTimeLabel(death.time);
      return label;
    }
  }

  handleNewInterval = (handleTime) => {
    this.handleTimeTemp = null;
    this.props.updateTimeInterval(handleTime[0], handleTime[1]);
  }

  rescaleDeaths = (deaths, map) => {
    const rescaledDeaths1 = [];
    const rescaledDeaths2 = [];
    deaths.forEach(death => {
      const rescaledDeath = {
        killer: death.killer,
        victim: death.victim,
        time: death.time,
        x : this.ratio*Math.round((death.position.x + map.ox) + (((death.position.x + map.ox)-map.width/2)/map.rx)),
        y : this.ratio*Math.round((death.position.y + map.oy) + (((death.position.y + map.oy)-map.height/2)/map.ry)),
        r : this.radius,
        team: death.team
      }
      if (this.props.isLeft) {
        if (death.team === this.props.team) rescaledDeaths1.push(rescaledDeath);
        else rescaledDeaths2.push(rescaledDeath);
      }
      else {
        if (death.team === this.props.team) rescaledDeaths2.push(rescaledDeath);
        else rescaledDeaths1.push(rescaledDeath);
      }
    })
    return [rescaledDeaths1, rescaledDeaths2]
  }


  addStartingPos = (map, deaths1, deaths2) => {
    if ((deaths1.length > 0 && deaths1[0].team === 0) 
      || (deaths2.length > 0 && deaths2[0].team !== 0)) {
      deaths1.push({x: 5, 
                         y: this.ratio*map.height-5, 
                         r: this.radiusTeam,
                         start: true})
      deaths2.push({x: this.ratio*map.width-5, 
                         y: this.ratio*map.height-5, 
                         r: this.radiusTeam,
                         start: true})
    }
    else {
      deaths2.push({x: 5, 
                         y: this.ratio*map.height-5, 
                         r: this.radiusTeam,
                         start: true})
      deaths1.push({x: this.ratio*map.width-5, 
                         y: this.ratio*map.height-5, 
                         r: this.radiusTeam,
                         start: true})
    }
    return [deaths1, deaths2]
  }

  parseObjectives = (colors) => {
    this.objectivesMarks = {};
    this.objectivesInfo = [];
    let n = 1;
    const objectives = this.props.objectives.slice().sort((a, b) => timeToSec(a.time) <= timeToSec(b.time) ? -1 : 1)
    let prevTime = 0
    objectives.forEach(objective => {
      const time = timeToSec(objective.time);
      if (time - prevTime < 120 && this.props.map === "Infernal Shrines") {
        delete this.objectivesMarks[prevTime]
        n -= 1
      }
      let color = "";
      if (this.props.isLeft) {
        if (objective.team === this.props.team) color = colors[0];
        else color = colors[1];
      }
      else {
        if (objective.team === this.props.team) color = colors[1];
        else color = colors[0];
      }
      const teamName = color === colors[0] ? this.props.teamName1 : this.props.teamName2;
      this.objectivesInfo.push({dotColor: color, teamName, timestamp:time, label:"Objective: "+n, nObj: n});
      this.objectivesMarks[time] = {};
      n += 1;
      prevTime = time;
    })
  }

  render() {
    const myColor = ["#0387C5","#1976d2"];
    const enemyColor = ["#D62752","#d32f2f"];
    const map = mapNameToData(this.props.map);
    if (map !== undefined) {
      const deaths = this.rescaleDeaths(this.props.deaths, map);
      const duration = timeToSec(this.props.duration)
      const realEndTime = this.props.endTime !== 0 ? this.props.endTime : duration;
      let newDeaths1 = extractDeathInRange(deaths[0], this.props.startTime, realEndTime);
      let newDeaths2 = extractDeathInRange(deaths[1], this.props.startTime, realEndTime);
      [newDeaths1, newDeaths2] = this.addStartingPos(map, [...newDeaths1], [...newDeaths2]);
      this.deaths1 = newDeaths1;
      this.deaths2 = newDeaths2;
      this.parseObjectives(["#0387C5", "#D62752"]);
      let start = this.props.startTime;
      let end = realEndTime;
      if (this.handleTimeTemp) {
        start = this.handleTimeTemp[0];
        end = this.handleTimeTemp[1];
      }

      return (
          <div className="marginTop">
            <div style={{maxWidth:map.width*this.ratio, margin:'auto'}}>
              <Range className="range"
                    min={0} max={duration}
                    allowCross={false}
                    onAfterChange={this.handleNewInterval}
                    onChange={this.handleOnChange}
                    defaultValue={[0, duration]}
                    marks={this.objectivesMarks}
                    value={[start, end]}
                    pushable={duration/20}
                    ref={(reference) => this.rangeRef = reference}
                    tipFormatter={value => secToTimestamp(value)}/> 
            </div>
            <div style={{backgroundImage:map.image, backgroundSize:"contain", maxHeight:map.height*this.ratio, maxWidth:map.width*this.ratio, margin:"auto"}} className="teambox pad-map-up" >
              <Bubble id={"death-canvas"} 
                      height={map.height*this.ratio} 
                      width={map.width*this.ratio}
                      data={{
                        datasets: [
                          {
                            data: newDeaths1,
                            backgroundColor: myColor[0],
                            hoverBackgroundColor: myColor[1],
                            hoverBorderColor: "#000000",
                            label: "left",
                          },
                          {
                            data: newDeaths2,
                            backgroundColor: enemyColor[0],
                            hoverBackgroundColor: enemyColor[1],
                            hoverBorderColor: "#000000",
                            label:"right",
                          }]
                      }}
                      options={{
                        legend: {display:false},
                        tooltips: {
                          enabled: true,
                          displayColors: false,
                          bodyFontFamily: "MetronicForBlizzard",
                          titleFontFamily: "MetronicForBlizzard",
                          callbacks: {
                            label: this.handleLabel,
                            beforeBody: this.handleLabelArray,
                            title: this.handleTitle,
                          },
                        },
                        scales: {xAxes: [{display: false, ticks:{min: 0, max: this.ratio*map.width}}], 
                                yAxes: [{display: false, ticks:{min: 0, max: this.ratio*map.height}}]},
                        maintainAspectRatio: true,
                        plugins: {
                          datalabels: {
                            display: false
                          }
                        },
                      }}
                      
                      redraw
              />
            </div>
          </div>
      );
    }
    else {
      return (
        <div>
          <h4 className="hots-text">No map available</h4>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    startTime: state.deathmap.startTime,
    endTime: state.deathmap.endTime,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTimeInterval: (start, end) => dispatch(updateRange(start, end)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Deathmap);