import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {formatBigNumber} from '../../functions/hotsUtils';


class ExpGraph extends Component {

  render() {
    const redColor = "#D62752";
    const blueColor = "#0387C5";
    return (
      <div className="teambox">
        <Line data={{ labels: this.props.timeStamps,
                    datasets: [
                    {
                      label: this.props.teamName1,
                      data: this.props.xpTeam1,
                      fill: false,
                      backgroundColor: blueColor,
                      borderColor: blueColor,
                    },
                    {
                      label: this.props.teamName2,
                      data: this.props.xpTeam2,
                      fill: false,
                      backgroundColor: redColor,
                      borderColor: redColor,
                    }
                  ],
                  }}
              options={{
                responsive: true,
                showLines: true,
                layout: {
                  padding: {
                      left: 0,
                      right: 20,
                      top: 0,
                      bottom: 0
                  }
                },
                tooltips: {
                  mode: 'index',
                  intersect: false,
                  displayColors: true,
                  bodyFontFamily: "MetronicForBlizzard",
                  titleFontFamily: "MetronicForBlizzard",
                  callbacks: {
                    label: function(tooltipItems, data) { 
                      return formatBigNumber(tooltipItems.yLabel)
                    }
                  }
                },
                legend: {
                  display:true,
                  labels: {
                    padding: 10,
                    fontFamily: 'MetronicForBlizzard',
                    fontColor: "#FFFFFF",
                  }
                },
                
                hover: {
                  mode: 'nearest',
                  intersect: true
                },
                scales: {
                  xAxes: [{
                    ticks: {
                      fontFamily: 'MetronicForBlizzard',
                      fontColor: "#FFFFFF",
                    },
                    display: true,
                    scaleLabel: {
                      display: true,
                      labelString: 'Time (minutes)',
                      fontFamily: 'MetronicForBlizzard',
                      fontColor: "#FFFFFF",
                      padding: {
                        top: 0,
                        bottom: 4,
                      },
                    }
                  }],
                  yAxes: [{
                    display: true,
                    ticks: {
                      callback: function(value, index, values) {
                          return Math.round(value/1000)+"k";
                      },
                      fontFamily: 'MetronicForBlizzard',
                      fontColor: "#FFFFFF",
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'XP',
                      fontFamily: 'MetronicForBlizzard',
                      fontColor: "#FFFFFF",
                    }
                  }]
                },
                plugins: {
                  datalabels: {
                    display: false,
                  },
              }
              }}
              height={400}
              plugins={[ChartDataLabels]}
        />
      </div>
    );
  }
}

export default ExpGraph;