import React, { Component } from 'react';
import Diamond from './Diamond';
import {heroToIcon} from '../../functions/translators'

class Lineup extends Component {
  render() {
    const perfs = this.props.noperfs ?
      <div></div>
      :
      <div className="row no-space-at-all">
          <div className="col s1"></div>
          <span className="col s2 hots-text">{this.props.heroes[0][1]}</span>
          <span className="col s2 hots-text">{this.props.heroes[1][1]}</span>
          <span className="col s2 hots-text">{this.props.heroes[2][1]}</span>
          <span className="col s2 hots-text">{this.props.heroes[3][1]}</span>
          <span className="col s2 hots-text">{this.props.heroes[4][1]}</span>
          <div className="col s1"></div>
        </div>
    return ( 
      <div className="row no-space">
        <div className="row no-space-at-all">
          <div className="col s1"></div>
          <Diamond hero={heroToIcon(this.props.heroes[0][0])} col="s2"/>
          <Diamond hero={heroToIcon(this.props.heroes[1][0])} col="s2"/>
          <Diamond hero={heroToIcon(this.props.heroes[2][0])} col="s2"/>
          <Diamond hero={heroToIcon(this.props.heroes[3][0])} col="s2"/>
          <Diamond hero={heroToIcon(this.props.heroes[4][0])} col="s2"/>
          <div className="col s1"></div>
        </div>
        {perfs}
      </div>
    );
  }
}

export default Lineup;