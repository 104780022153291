import React, { Component } from 'react';
import './HeadToHead.css';
import { connect } from 'react-redux';
import DropdownH2H from '../layout/DropdownH2H';
import Bar from '../layout/Bar';
import LineupH2H from '../layout/LineupH2H';
import ExpGraph from './ExpGraph';
import DeathMap from './Deathmap';
import GameStats from './GameStats';
import { extractVictories, getSide, extractXPOverTime, getBreakdownTimeStamps, extractStatsFromReplay } from '../../functions/gameAnalysis'

class HeadToHead extends Component {


  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentDidUpdate() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (document.getElementById('death-canvas')) {
      const width = document.getElementById('death-canvas').offsetWidth;
      //this.setState({});
      const doc = document.getElementById("col-text-death");
      doc.style.width = width+"px";
      doc.style.margin = "auto";
    }

    //if (document.getElementById('col-stats-h2h')) {
    //  const height = document.getElementById('col-stats-h2h').offsetHeight;
    //  document.getElementById("col-exp-h2h").style.height = height+"px";
    //}

  }

  renderHeadToHead = () => {
    let victories = [0, 0];
    if (this.props.team1) {
      victories = extractVictories(this.props.matches, this.props.team1, true);
    } else if (this.props.team2) {
      victories = extractVictories(this.props.matches, this.props.team2, false);
    }
    let side, players1, players2, xpBreakDowns1, xpBreakDowns2, isLeft, members;
    if (this.props.team1) {
      isLeft = true;
      members = this.props.team1.members;
      side = getSide(this.props.currentMatch, this.props.team1.members);
      players1 = this.props.currentMatch["players"+side];
      players2 = this.props.currentMatch["players"+(-side+3)];
      xpBreakDowns1 = this.props.currentMatch["XPBreakdown"+side];
      xpBreakDowns2 = this.props.currentMatch["XPBreakdown"+(-side+3)];
    } else {
      isLeft = false;
      members = this.props.team2.members;
      side = getSide(this.props.currentMatch, this.props.team2.members);
      players1 = this.props.currentMatch["players"+(-side+3)];
      players2 = this.props.currentMatch["players"+side];
      xpBreakDowns1 = this.props.currentMatch["XPBreakdown"+(-side+3)];
      xpBreakDowns2 = this.props.currentMatch["XPBreakdown"+side];
    }
    let teamName1_xp = "";
    let teamName2_xp = "";
    if (this.props.team1 && !this.props.team2) {
      teamName1_xp = this.props.teamName1;
      teamName2_xp = "Opponent team"
    } else if (!this.props.team1 && this.props.team2) {
      teamName2_xp = this.props.teamName2;
      teamName1_xp = "Opponent team";
    } else {
      teamName1_xp = this.props.teamName1;
      teamName2_xp = this.props.teamName2;
    }
    const statsSummary = extractStatsFromReplay(this.props.currentMatch);
    return (
      <div>
        <div className="row row-smallerMargin">
          <div className="col s0 m0 l4"></div>
          <div className="col s12 m12 l4">
            <Bar className="row no-space" values={victories}/>
            <DropdownH2H className="row no-space"
              currentMatch={this.props.currentMatch} 
              matches={this.props.matches}
              team={members}
              isLeft={isLeft}/>
          </div>
          <div className="col s0 m0 l4"></div>
        </div>
        <div className="row no-space">
          <div id="col-exp-h2h" className="col s12 m12 l3 no-space-at-all move-up">
            <LineupH2H className="col s12" side={0} teamMembers={players1} summary={statsSummary}/>
            <ExpGraph className="col s12" timeStamps={getBreakdownTimeStamps(xpBreakDowns1)}
                      xpTeam1={extractXPOverTime(xpBreakDowns1)}
                      xpTeam2={extractXPOverTime(xpBreakDowns2)}
                      teamName1={teamName1_xp}
                      teamName2={teamName2_xp}/>
            <div className="col s12 h2h-tab">
              <span className={"hots-text span-fill col s12"}>
                XP Graph
              </span>
            </div>
            <hr className="col s12 hots-hline"></hr>
          </div>
          <div id="col-death-full" className="col s12 m12 l6 center-text">
            <DeathMap className="col s12" map={this.props.currentMatch.map} deaths={this.props.currentMatch.deaths} team={side-1}
                      teamName1={teamName1_xp} teamName2={teamName2_xp} isLeft={isLeft} duration={this.props.currentMatch.duration}
                      objectives={this.props.currentMatch.objectives}/>
            <div id="col-text-death" className="margin-center down">
              <div className="col s12 h2h-tab">
                <span className="hots-text span-fill col s12 center">
                  Death Map
                </span>
              </div>
              <hr className="col s12 hots-hline"></hr>
            </div>
          </div>
          <div id="col-stats-h2h" className="col s12 m12 l3 no-space-at-all move-up">
            <LineupH2H className="col s12" side={1} teamMembers={players2} summary={statsSummary}/>
            <GameStats className="col s12"players={players1.concat(players2)}/>
            <div className="col s12 h2h-tab">
              <span className={"hots-text span-fill col s12"}>
                Game Statistics
              </span>
            </div>
            <hr className="col s12 hots-hline"></hr>
          </div>
        </div>

      </div>
    );
  }

  render() {
    if (!this.props.team1 && !this.props.team2) {
      return (
        <div className="row">
          <div className="col s12 m12 l12"><span className="span-fill hots-text txt-medium">Select at least one team!</span></div>
        </div>
      );

    } else if (!this.props.team1 && this.props.matches.length === 0) {
      return (
        <div className="row">
          <div className="col s12 m12 l12"><span className="span-fill hots-text txt-medium">{this.props.teamName2+" doesn't match any replay in our database!"}</span></div>
        </div>
      );
    } else if (!this.props.team2 && this.props.matches.length === 0) {
      return (
        <div className="row">
          <div className="col s12 m12 l12"><span className="span-fill hots-text txt-medium">{this.props.teamName1+" doesn't match any replay in our database!"}</span></div>
        </div>
      );
    } else if ((this.props.team1 && this.props.team2) && this.props.matches.length === 0) {
      return (
        <div className="row">
          <div className="col s12 m12 l12">
            <span className="span-fill hots-text txt-medium">{"There is no encounter between \""+this.props.teamName1+"\" and \""+this.props.teamName2+"\" in our database!"}
            </span>
          </div>
        </div>
      );
    } else {
      if (getSide(this.props.currentMatch, this.props.team1 ? this.props.team1.members : this.props.team2.members) === -1) {
        return (
          <div className="row">
            <div className="col s12 m12 l12"><span className="span-fill hots-text txt-medium">{"Loading"}</span></div>
          </div>
        );
      } else {
        return (
          this.renderHeadToHead()
        );
      } 
    }
  }
}


const mapStateToProps = (state) => {
  return {
    matches: state.team.replays,
    currentMatch: state.team.currentMatch,
    team1: state.team.myTeam,
    team2: state.team.opponentTeam,
    teamName1: state.team.myTeamName,
    teamName2: state.team.opponentTeamName,
  }
}

export default connect(mapStateToProps, null)(HeadToHead);